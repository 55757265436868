import Grid from '@material-ui/core/Grid';
import { PresetReportDto, ReportType } from 'api/admin/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FieldGroup from 'components/forms/styled-fields/FieldGroup';
import { selectIsActiveDomainApciEnabled } from 'redux-app/modules/app/selectors';
import { FilterOptions, GroupByOptions } from '../types';

import { EditorDropDown, EditorTextBox } from '../common/EditorFields';

const FormAssetDataChannelSiteList = ({
  reportType,
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  values,
  filterOptions1,
}: {
  data?: PresetReportDto;
  reportType: ReportType;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
  values?: PresetReportDto;
  filterOptions1: { [key: string]: string };
}) => {
  const { t } = useTranslation();

  const isAirProductsEnabledDomain = useSelector(
    selectIsActiveDomainApciEnabled
  );

  const selectFilterByOptions = () => {
    switch (reportType) {
      case ReportType.DataChannelList:
        return {
          [FilterOptions.AssetTitle]: t(
            'ui.datachannel.assettitle',
            'Asset Title'
          ),
          [FilterOptions.Description]: t(
            'ui.common.description',
            'Description'
          ),
          [FilterOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
          [FilterOptions.RTU]: t('ui.common.rtu', 'RTU'),
          [FilterOptions.Type]: t('ui.common.type', 'Type'),
          [FilterOptions.PublishedComments]: t(
            'ui.common.publishedcomments',
            'Published Comments'
          ),
        };
      case ReportType.SiteList:
        return {
          [FilterOptions.CustomerName]: isAirProductsEnabledDomain
            ? t('ui.common.shipTo', 'Ship To')
            : t('ui.common.customername', 'Customer Name'),
          [FilterOptions.City]: t('ui.common.city', 'City'),
          [FilterOptions.Country]: t('ui.common.country', 'Country'),
          [FilterOptions.State]: t('ui.common.state', 'State'),
        };

      case ReportType.RTUList:
        return {
          [FilterOptions.Carrier]: t('ui.common.carrier', 'Carrier'),

          [FilterOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
          [FilterOptions.RTU]: t('ui.common.rtu', 'RTU'),
          [FilterOptions.RTUphone]: t('ui.common.rtuphone', 'RTU Phone'),
          [FilterOptions.Site]: t('ui.common.site', 'Site'),
        };
      default:
        return filterOptions1;
    }
  };

  const selectGroupByOptions = () => {
    switch (reportType) {
      case ReportType.SiteList:
        return {
          [GroupByOptions.None]: t('enum.common.none', 'None'),

          [GroupByOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
          [GroupByOptions.Country]: t('ui.common.country', 'Country'),
          [GroupByOptions.State]: t('ui.common.state', 'State'),
        };
      case ReportType.AssetList:
        return {
          [GroupByOptions.None]: t('enum.common.none', 'None'),
          [GroupByOptions.Asset]: t('ui.datachannel.asset', 'Asset '),

          [GroupByOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
          [GroupByOptions.Country]: t('ui.common.country', 'Country'),
          [GroupByOptions.State]: t('ui.common.state', 'State'),
        };
      case ReportType.DataChannelList:
        return {
          [GroupByOptions.None]: t('enum.common.none', 'None'),
          [GroupByOptions.Asset]: t('ui.datachannel.asset', 'Asset '),
          [GroupByOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
          [GroupByOptions.RTU]: t('ui.common.rtu', 'RTU'),
        };

      case ReportType.RTUList:
        return {
          [GroupByOptions.None]: t('enum.common.none', 'None'),
          [GroupByOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
        };

      default:
        return {
          [GroupByOptions.None]: t('enum.common.none', 'None'),
          [GroupByOptions.CustomerName]: t(
            'ui.common.customername',
            'Customer Name'
          ),
          [GroupByOptions.Country]: t('ui.common.country', 'Country'),
          [GroupByOptions.State]: t('ui.common.state', 'State'),
        };
    }
  };

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            nonSort
            isMandatory
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.groupby', 'Group By')}`}
            name="groupByEnumValue"
            textMapping={selectGroupByOptions()}
            isMandatory
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FieldGroup style={{ display: 'flex' }}>
            <EditorDropDown
              label={`${t('ui.reports.filterby', 'Filter By')}`}
              name="filterByEnumValue"
              textMapping={selectFilterByOptions()}
              style={{ minWidth: '200px' }}
              isMandatory
            />

            <EditorTextBox
              name="filterText"
              styleInput={{ minWidth: '300px', marginTop: '18px' }}
              placeholder={t(
                'ui.common.filterplaceholder',
                `Enter {{filterOption}}`,
                {
                  filterOption: selectFilterByOptions()[
                    // @ts-ignore
                    values.filterByEnumValue
                  ],
                }
              )}
            />
          </FieldGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default FormAssetDataChannelSiteList;
