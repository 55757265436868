import Grid from '@material-ui/core/Grid';
import { PresetReportDto } from 'api/admin/api';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import StyledKeyboardDatePickerLegacy from 'components/forms/styled-fields/StyledKeyboardDatePickerLegacy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'components/forms/styled-fields/MultiSelect';
import { EditorDropDown } from '../common/EditorFields';
import { StyledFieldLabelText } from '../common/EditorFields/styles';

export const StyledValueText = styled(Typography)`
  font-size: ${(props) => props.theme.custom.fontSize?.tableCells};
`;

const FormMissingData = ({
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  optionsDomains,
  localStartDate,
  setLocalStartDate,
  localEndDate,
  setLocalEndDate,
  dataCarriersOptions,
  selectedCarriers,
  setSelectedCarriers,
  setSelectedDomain,
  selectedDomain,
  setValue,
}: {
  data?: PresetReportDto;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
  optionsDomains: { [key: string]: string };
  localStartDate: moment.Moment | null;
  localEndDate: moment.Moment | null;
  setLocalStartDate: any;
  setLocalEndDate: any;
  setSelectedDomain: any;
  dataCarriersOptions: string[];
  selectedCarriers: string[];
  setSelectedCarriers: any;
  selectedDomain?: string | null;
  setValue: any;
}) => {
  const { t } = useTranslation();

  const handleChangeCarriers = (carriersValues: string[]) => {
    setSelectedCarriers(carriersValues);
  };

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            isMandatory
            nonSort
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.domain', 'Domain')}`}
            name="selectedDomainId"
            nonSort
            isMandatory
            textMapping={optionsDomains}
            selectprops={{
              value: selectedDomain,
              onChange: (valDomain: any) => {
                setSelectedDomain(valDomain.target.value);
                setValue('selectedDomainId', valDomain.target.value);
                handleChangeCarriers([]);
              },
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <MultiSelect<string>
            options={dataCarriersOptions}
            value={selectedCarriers}
            setValue={handleChangeCarriers}
            label={
              <StyledFieldLabelText>
                {t('ui.reports.carrier', 'Carrier')}
              </StyledFieldLabelText>
            }
            onClick={(event) => event.stopPropagation()}
            renderValue={(option) => option}
            style={{}}
            InputProps={{
              style: {
                overflow: 'hidden',
                marginTop: '0px',
              },
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <StyledFieldLabelText>
            {t('ui.reports.timeperiod', 'Time Period')}
          </StyledFieldLabelText>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <StyledKeyboardDatePickerLegacy
                id="startDate-input"
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
                PopoverProps={{ id: 'start date popover' }}
                value={localStartDate}
                onChange={setLocalStartDate}
                maxDate={localEndDate}
                style={{ width: 135 }}
              />
            </Grid>
            <Grid item>
              <StyledValueText>{t('ui.common.to', 'To')}</StyledValueText>
            </Grid>
            <Grid item>
              <StyledKeyboardDatePickerLegacy
                id="endDate-input"
                KeyboardButtonProps={{ 'aria-label': 'change end date' }}
                PopoverProps={{ id: 'end date popover' }}
                value={localEndDate}
                onChange={setLocalEndDate}
                minDate={localStartDate}
                style={{ width: 135 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormMissingData;
