import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';
import { EvolveGetDataChannelsByDomainIdAndUserIdRequest } from 'api/admin/api';

const retrieveInventoryStates = (domainId: string, userId: string) => {
  return ApiService.InventoryStatesService.getInventoryStatesByDomainIdAndUserId_GetInventoryStatesByDomainIdAndUserId(
    { domainId, userId } as EvolveGetDataChannelsByDomainIdAndUserIdRequest
  );
};

const useGetInventoryStates = (domainId: string, userId: string) => {
  return useQuery([APIQueryKey.retrieveInventoryStates, domainId, userId], () =>
    retrieveInventoryStates(domainId!, userId!)
  );
};

export default useGetInventoryStates;
