import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';

const retrieveReportSchedules = (presetReportId: number) => {
  return ApiService.PresetReportService.presetReport_RetrievePresetReportSchedules(
    presetReportId
  );
};

const useGetReportSchedules = (presetReportId: number) => {
  return useQuery([APIQueryKey.retrieveReportSchedules, presetReportId], () =>
    retrieveReportSchedules(presetReportId!)
  );
};

export default useGetReportSchedules;
