import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { PresetReportDto, ReportTimePeriodType } from 'api/admin/api';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FieldGroup from 'components/forms/styled-fields/FieldGroup';
import { selectIsActiveDomainApciEnabled } from 'redux-app/modules/app/selectors';
import MultiSelect from 'components/forms/styled-fields/MultiSelect';
import StyledKeyboardDatePickerLegacy from 'components/forms/styled-fields/StyledKeyboardDatePickerLegacy';
import { buildReportTimePeriodTypeTextMapping } from 'utils/i18n/enum-to-text';
import { unitOptions, GroupByOptions, FilterOptions } from '../types';

import {
  EditorDropDown,
  EditorSwitch,
  EditorTextBox,
} from '../common/EditorFields';
import { StyledFieldLabelText } from '../common/EditorFields/styles';

export const StyledValueText = styled(Typography)`
  font-size: ${(props) => props.theme.custom.fontSize?.tableCells};
`;

const FormNewAsset = ({
  data,
  localStartDate,
  setLocalStartDate,
  localEndDate,
  setLocalEndDate,
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  assetGroupOptions,
  values,
  dataChannelsOptions,
  selectedDataChannels,
  setSelectedDataChannels,
}: {
  data?: PresetReportDto;
  localStartDate: moment.Moment | null;
  localEndDate: moment.Moment | null;
  setLocalStartDate: any;
  setLocalEndDate: any;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
  assetGroupOptions: { [key: string]: string };
  values?: PresetReportDto;
  selectedDataChannels: string[];
  setSelectedDataChannels: any;
  dataChannelsOptions: string[];
}) => {
  const { t } = useTranslation();
  const showDates = useMemo(() => {
    if (typeof values?.reportTimePeriodType === 'string') {
      return (
        Number(values?.reportTimePeriodType) === ReportTimePeriodType.Custom
      );
    }
    return false;
  }, [values?.reportTimePeriodType]);

  const { includeSubDomains } = data || {};

  const isAirProductsEnabledDomain = useSelector(
    selectIsActiveDomainApciEnabled
  );

  const selectFilterByOptions = () => {
    return {
      [FilterOptions.AllFields]: t('enum.common.allfields', 'All Fields'),
      [FilterOptions.Site]: t('ui.datachannel.assetlocation', 'Asset Location'),
      [FilterOptions.AssetTitle]: t('ui.datachannel.assettitle', 'Asset Title'),
      [FilterOptions.CustomerName]: isAirProductsEnabledDomain
        ? t('ui.common.shipTo', 'Ship To')
        : t('ui.common.customername', 'Customer Name'),
      [FilterOptions.FTPID]: t('ui.common.ftpid', 'Integration ID'),
      [FilterOptions.Product]: t('ui.common.product', 'Product'),
      [FilterOptions.RTU]: t('ui.common.rtu', 'RTU'),
    };
  };

  const groupByOptions = () => ({
    [GroupByOptions.None]: t('enum.common.none', 'None'),
    [GroupByOptions.Asset]: t('ui.datachannel.asset', 'Asset '),
    [GroupByOptions.Location]: t(
      'ui.datachannel.assetlocation',
      'Asset Location'
    ),
    [GroupByOptions.CustomerName]: t('ui.common.customername', 'Customer Name'),
  });

  const timePeriodOptions = buildReportTimePeriodTypeTextMapping(t);

  const handleChangeDataChannel = (dcValues: string[]) => {
    setSelectedDataChannels(dcValues);
  };

  // @ts-ignore
  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={2} xs={12}>
          <EditorSwitch
            label={t('ui.reports.includesubdomains', 'Include Sub Domains')}
            name="includeSubDomains"
            switchLabel={
              includeSubDomains
                ? t('ui.common.yes', 'Yes')
                : t('ui.common.no', 'No')
            }
          />
        </Grid>
        <Grid item xs={4}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            isMandatory
            nonSort
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
          />
        </Grid>
        <Grid item xs={4}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <EditorDropDown
            label={`${t('ui.reports.groupby', 'Group By')}`}
            name="groupByEnumValue"
            textMapping={groupByOptions()}
            isMandatory
          />
        </Grid>
        <Grid item xs={5}>
          <FieldGroup style={{ display: 'flex' }}>
            <EditorDropDown
              label={`${t('ui.reports.filterby', 'Filter By')}`}
              name="filterByEnumValue"
              textMapping={selectFilterByOptions()}
              style={{ minWidth: '200px' }}
              isMandatory
            />

            <EditorTextBox
              name="filterText"
              styleInput={{ minWidth: '300px', marginTop: '18px' }}
              placeholder={t(
                'ui.common.filterplaceholder',
                `Enter {{filterOption}}`,
                // @ts-ignore
                {
                  filterOption: selectFilterByOptions()[
                    // @ts-ignore
                    values.filterByEnumValue
                  ],
                }
              )}
            />
          </FieldGroup>
        </Grid>
        <Grid item xs={3}>
          <EditorDropDown
            label={`${t('ui.reports.units', 'Units')}`}
            name="unitTypeEnumValue"
            textMapping={unitOptions(t)}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <MultiSelect<string>
            options={dataChannelsOptions}
            value={selectedDataChannels}
            setValue={handleChangeDataChannel}
            label={
              <StyledFieldLabelText>
                {t('ui.common.datachanneltype', 'Data Channel Type')}
              </StyledFieldLabelText>
            }
            onClick={(event) => event.stopPropagation()}
            renderValue={(option) => option}
            style={{}}
            InputProps={{
              style: {
                overflow: 'hidden',
                marginTop: '0px',
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <EditorDropDown
            label={`${t('ui.reports.assetgroup', 'Asset Group')}`}
            name="assetGroupId"
            textMapping={assetGroupOptions}
            isMandatory
          />
        </Grid>
        <Grid item xs={4}>
          <EditorDropDown
            label={`${t('ui.reports.timeperiod', 'Time Period')}`}
            name="reportTimePeriodType"
            textMapping={timePeriodOptions}
            isMandatory
          />
          {showDates && (
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginTop: '8px' }}
            >
              <Grid item>
                <StyledKeyboardDatePickerLegacy
                  id="startDate-input"
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                  PopoverProps={{ id: 'start date popover' }}
                  value={localStartDate}
                  onChange={setLocalStartDate}
                  maxDate={localEndDate}
                  style={{ width: 135 }}
                />
              </Grid>
              <Grid item>
                <StyledValueText>{t('ui.common.to', 'To')}</StyledValueText>
              </Grid>
              <Grid item>
                <StyledKeyboardDatePickerLegacy
                  id="endDate-input"
                  KeyboardButtonProps={{ 'aria-label': 'change end date' }}
                  PopoverProps={{ id: 'end date popover' }}
                  value={localEndDate}
                  onChange={setLocalEndDate}
                  minDate={localStartDate}
                  style={{ width: 135 }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormNewAsset;
