/* eslint-disable indent */
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const StyledFieldLabelText = styled(Typography)`
  font-weight: 500;
  font-size: ${(props) => props.theme.custom.fontSize?.tableCells};
  line-height: ${(props) => props.theme.custom.fontSize?.commonLineHeight};
  margin-bottom: 5px;
`;

export const StyledValueText = styled(Typography)`
  font-size: ${(props) => props.theme.custom.fontSize?.tableCells};
`;
