import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import React from 'react';
import EditorBox from '../../../../components/EditorBox';
import MessageBlock from '../../../../components/MessageBlock';
import SearchCloudIcon from '../../../../components/icons/PermissionDeniedIcon';
import BoldPrimaryText from '../../../../components/typography/BoldPrimaryText';

const UnableToReadReports = () => {
  const { t } = useTranslation();
  return (
    <EditorBox py={2} mt={2}>
      <MessageBlock>
        <Box m={2}>
          <SearchCloudIcon />
        </Box>
        <BoldPrimaryText>
          {t(
            'ui.presetReport.unableToReadReports',
            'No permission to view reports, If you need access please call your administrator'
          )}
        </BoldPrimaryText>
      </MessageBlock>
    </EditorBox>
  );
};
export default UnableToReadReports;
