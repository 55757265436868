import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';

export const useRetrievePresetReportQueries = (
  presetReportId?: number | null
) => {
  const response = useQuery(
    [APIQueryKey.retrievePresetReportQueries, presetReportId],
    () => {
      return ApiService.ReportQueryService.reportQuery_Index(
        presetReportId || 0
      );
    },
    {
      enabled: !!presetReportId,
    }
  );
  return { ...response, data: response.data };
};
