import Grid from '@material-ui/core/Grid';
import { PresetReportDto } from 'api/admin/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'components/forms/styled-fields/MultiSelect';
import FieldGroup from 'components/forms/styled-fields/FieldGroup';
import { FilterOptions, GroupByOptions } from '../types';

import {
  EditorDropDown,
  EditorSwitch,
  EditorTextBox,
} from '../common/EditorFields';
import { StyledFieldLabelText } from '../common/EditorFields/styles';

const FormMissingReadingBatteryVoltage = ({
  data,
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  assetGroupOptions,
  values,
  dataChannelsOptions,
  selectedDataChannels,
  setSelectedDataChannels,
}: {
  data?: PresetReportDto;
  optionsTimeZone: { [key: string]: string };
  optionsLanguage: { [key: string]: string };
  assetGroupOptions: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  values?: PresetReportDto;
  selectedDataChannels: string[];
  setSelectedDataChannels: any;
  dataChannelsOptions: string[];
}) => {
  const { t } = useTranslation();
  const { includeSubDomains } = data || {};

  const handleChangeDataChannel = (dcValues: string[]) => {
    setSelectedDataChannels(dcValues);
  };

  const filterByOptions = () => ({
    [FilterOptions.Carrier]: t('enum.rtulistfilteroptions.carrier', 'Carrier'),
    [FilterOptions.AssetTitle]: t('ui.datachannel.assettitle', 'Asset Title'),
    [FilterOptions.Description]: t('ui.common.description', 'Description'),
    [FilterOptions.CustomerName]: t('ui.common.customername', 'Customer Name'),
    [FilterOptions.Domain]: t('ui.common.domain', 'Domain'),
    [FilterOptions.RTU]: t('ui.common.rtu', 'RTU'),
    [FilterOptions.Type]: t('ui.common.type', 'Type'),
  });

  const groupByOptions = () => ({
    [GroupByOptions.None]: t('enum.common.none', 'None'),
    [GroupByOptions.Asset]: t('ui.datachannel.asset', 'Asset '),
    [GroupByOptions.Location]: t(
      'ui.datachannel.assetlocation',
      'Asset Location'
    ),
    [GroupByOptions.CustomerName]: t('ui.common.customername', 'Customer Name'),
    [GroupByOptions.Domain]: t('ui.common.domain', 'Domain'),
    [GroupByOptions.RTU]: t('ui.common.rtu', 'RTU'),
    [GroupByOptions.Carrier]: t('ui.common.carrier', 'Carrier'),
  });

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={2} xs={12}>
          <EditorSwitch
            label={t('ui.reports.includesubdomains', 'Include Sub Domains')}
            name="includeSubDomains"
            switchLabel={
              includeSubDomains
                ? t('ui.common.yes', 'Yes')
                : t('ui.common.no', 'No')
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            isMandatory
            nonSort
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.groupby', 'Group By')}`}
            name="groupByEnumValue"
            textMapping={groupByOptions()}
            isMandatory
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FieldGroup style={{ display: 'flex' }}>
            <EditorDropDown
              label={`${t('ui.reports.filterby', 'Filter By')}`}
              name="filterByEnumValue"
              textMapping={filterByOptions()}
              style={{ minWidth: '200px' }}
              isMandatory
            />

            <EditorTextBox
              name="filterText"
              styleInput={{ minWidth: '300px', marginTop: '18px' }}
              placeholder={t(
                'ui.common.filterplaceholder',
                `Enter {{filterOption}}`,
                // @ts-ignore
                { filterOption: filterByOptions()[values.filterByEnumValue] }
              )}
            />
          </FieldGroup>
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <MultiSelect<string>
            options={dataChannelsOptions}
            value={selectedDataChannels}
            setValue={handleChangeDataChannel}
            label={
              <StyledFieldLabelText>
                {t('ui.common.datachanneltype', 'Data Channel Type')}
              </StyledFieldLabelText>
            }
            onClick={(event) => event.stopPropagation()}
            renderValue={(option) => option}
            style={{}}
            InputProps={{
              style: {
                overflow: 'hidden',
                marginTop: '0px',
              },
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.assetgroup', 'Asset Group')}`}
            name="assetGroupId"
            textMapping={assetGroupOptions}
            isMandatory
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormMissingReadingBatteryVoltage;
