import Grid from '@material-ui/core/Grid';
import { PresetReportDto } from 'api/admin/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FieldGroup from 'components/forms/styled-fields/FieldGroup';
import MultiSelect from 'components/forms/styled-fields/MultiSelect';
import { unitOptions } from '../types';

import {
  EditorDropDown,
  EditorSwitch,
  EditorTextBox,
} from '../common/EditorFields';
import { StyledFieldLabelText } from '../common/EditorFields/styles';

const FormCustomCSV = ({
  data,
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  assetGroupOptions,
  filterOptions1,
  values,
  dataChannelsOptions,
  selectedDataChannels,
  setSelectedDataChannels,
}: {
  data?: PresetReportDto;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
  assetGroupOptions: { [key: string]: string };
  filterOptions1: { [key: string]: string };
  values?: PresetReportDto;
  dataChannelsOptions: string[];
  selectedDataChannels: string[];
  setSelectedDataChannels: any;
}) => {
  const { t } = useTranslation();
  const { includeSubDomains } = data || {};

  const handleChangeDataChannel = (dcValues: string[]) => {
    setSelectedDataChannels(dcValues);
  };

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={2} xs={12}>
          <EditorSwitch
            label={t('ui.reports.includesubdomains', 'Include Sub Domains')}
            name="includeSubDomains"
            switchLabel={
              includeSubDomains
                ? t('ui.common.yes', 'Yes')
                : t('ui.common.no', 'No')
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            isMandatory
            nonSort
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        alignItems="center"
        style={{ marginBottom: '18px' }}
      >
        <Grid item md={5} sm={6} xs={12}>
          <FieldGroup style={{ display: 'flex', marginTop: '18px' }}>
            <EditorDropDown
              label={`${t('ui.reports.filterby', 'Filter By')}`}
              name="filterByEnumValue"
              textMapping={filterOptions1}
              style={{ minWidth: '12vw' }}
              isMandatory
            />

            <EditorTextBox
              name="filterText"
              styleInput={{ minWidth: '20vw', marginTop: '18px' }}
              placeholder={t(
                'ui.common.filterplaceholder',
                `Enter {{filterOption}}`,
                // @ts-ignore
                { filterOption: filterOptions1[values.filterByEnumValue] }
              )}
            />
          </FieldGroup>
        </Grid>
        <Grid item md={3} sm={6} xs={12} style={{ marginTop: '18px' }}>
          <EditorDropDown
            label={`${t('ui.reports.units', 'Units')}`}
            name="unitTypeEnumValue"
            textMapping={unitOptions(t)}
            isMandatory
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <MultiSelect<string>
            options={dataChannelsOptions}
            value={selectedDataChannels}
            setValue={handleChangeDataChannel}
            label={
              <StyledFieldLabelText>
                {t('ui.common.datachanneltype', 'Data Channel Type')}
              </StyledFieldLabelText>
            }
            onClick={(event) => event.stopPropagation()}
            renderValue={(option) => option}
            style={{}}
            InputProps={{
              style: {
                overflow: 'hidden',
                marginTop: '0px',
              },
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.assetgroup', 'Asset Group')}`}
            name="assetGroupId"
            textMapping={assetGroupOptions}
            isMandatory
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCustomCSV;
