import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { PresetReportDto, ReportTimePeriodType } from 'api/admin/api';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FieldGroup from 'components/forms/styled-fields/FieldGroup';
import StyledKeyboardDatePickerLegacy from 'components/forms/styled-fields/StyledKeyboardDatePickerLegacy';
import { buildReportTimePeriodTypeTextMapping } from 'utils/i18n/enum-to-text';
import { FilterOptions } from '../types';

import { EditorDropDown, EditorTextBox } from '../common/EditorFields';

export const StyledValueText = styled(Typography)`
  font-size: ${(props) => props.theme.custom.fontSize?.tableCells};
`;

const FormHornerDOE = ({
  localStartDate,
  setLocalStartDate,
  localEndDate,
  setLocalEndDate,
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  values,
}: {
  localStartDate: moment.Moment | null;
  localEndDate: moment.Moment | null;
  setLocalStartDate: any;
  setLocalEndDate: any;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
  values?: PresetReportDto;
}) => {
  const { t } = useTranslation();
  const showDates = useMemo(() => {
    if (typeof values?.reportTimePeriodType === 'string') {
      return (
        Number(values?.reportTimePeriodType) === ReportTimePeriodType.Custom
      );
    }
    return false;
  }, [values?.reportTimePeriodType]);

  const selectFilterByOptions = () => ({
    [FilterOptions.CustomerName]: t('ui.common.customername', 'Customer Name'),
    [FilterOptions.RTU]: t('ui.common.rtu', 'RTU'),
    [FilterOptions.Description]: t('ui.common.description', 'Description'),
    [FilterOptions.ShipTo]: t('ui.common.sitenumber', 'Site Number'),
  });

  const timePeriodOptions = buildReportTimePeriodTypeTextMapping(t);

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            isMandatory
            nonSort
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FieldGroup style={{ display: 'flex' }}>
            <EditorDropDown
              label={`${t('ui.reports.filterby', 'Filter By')}`}
              name="filterByEnumValue"
              textMapping={selectFilterByOptions()}
              style={{ minWidth: '200px' }}
              isMandatory
            />

            <EditorTextBox
              name="filterText"
              styleInput={{ minWidth: '330px', marginTop: '18px' }}
              placeholder={t(
                'ui.common.filterplaceholder',
                `Enter {{filterOption}}`,
                {
                  filterOption: selectFilterByOptions()[
                    // @ts-ignore
                    values.filterByEnumValue
                  ],
                }
              )}
            />
          </FieldGroup>
        </Grid>
      </Grid>

      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timeperiod', 'Time Period')}`}
            name="reportTimePeriodType"
            textMapping={timePeriodOptions}
            isMandatory
          />
          {showDates && (
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginTop: '8px' }}
            >
              <Grid item>
                <StyledKeyboardDatePickerLegacy
                  id="startDate-input"
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                  PopoverProps={{ id: 'start date popover' }}
                  value={localStartDate}
                  onChange={setLocalStartDate}
                  maxDate={localEndDate}
                  style={{ width: 135 }}
                />
              </Grid>
              <Grid item>
                <StyledValueText>{t('ui.common.to', 'To')}</StyledValueText>
              </Grid>
              <Grid item>
                <StyledKeyboardDatePickerLegacy
                  id="endDate-input"
                  KeyboardButtonProps={{ 'aria-label': 'change end date' }}
                  PopoverProps={{ id: 'end date popover' }}
                  value={localEndDate}
                  onChange={setLocalEndDate}
                  minDate={localStartDate}
                  style={{ width: 135 }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormHornerDOE;
