import useGetAssetGroups from './useGetAssetGroups';
import useGetDataChannels from './useGetDataChannels';
import useGetInventoryStates from './useGetInventoryStates';
import useGetPresetReportTemplates from './useGetPresetReportTemplates';
import useGetCarriers from './useGetCarriers';

const useGetDataChannelsAndInventory = (
  domainId: string,
  userId: string,
  selectedDomainId?: string | null | undefined
) => {
  const dataChannels = useGetDataChannels(domainId, userId);
  const assetGroups = useGetAssetGroups();
  const inventoryStates = useGetInventoryStates(domainId, userId);
  const templatesReport = useGetPresetReportTemplates();
  const carriers = useGetCarriers(selectedDomainId);

  return {
    isLoading:
      dataChannels.isLoading ||
      inventoryStates.isLoading ||
      assetGroups.isLoading ||
      templatesReport.isLoading ||
      carriers.isLoading,
    isError:
      dataChannels.isError ||
      inventoryStates.isError ||
      assetGroups.isError ||
      templatesReport.isError ||
      carriers.isError,
    data: {
      dataChannels: dataChannels.data,
      inventoryStates: inventoryStates.data,
      assetGroups: assetGroups.data,
      templatesReport: templatesReport.data,
      carriers: carriers.data,
    },
  };
};
export default useGetDataChannelsAndInventory;
