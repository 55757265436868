import Grid from '@material-ui/core/Grid';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { white, black, darkIconColorOpacity } from 'styles/colours';
import moment from 'moment';
import {
  PresetReportDto,
  PresetReportTemplateDto,
  PresetReportTemplateParameterDataType,
  PresetReportTemplateParameterModel,
} from 'api/admin/api';
import StyledKeyboardDatePickerLegacy from 'components/forms/styled-fields/StyledKeyboardDatePickerLegacy';

import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import {
  EditorDropDown,
  EditorSwitch,
  EditorTextBox,
} from '../common/EditorFields';
import { StyledFieldLabelText } from '../common/EditorFields/styles';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: ${white};
    color: ${black};
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 16px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }
  & .MuiTooltip-arrow {
    color: ${white};
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  height: 15px;
  width: 15px;
  margin-left: 5px;
  color: ${darkIconColorOpacity};
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const FormProcedure = ({
  data,
  values,
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsDomains,
  templatesReport,
  valuesParams,
  setValuesParams,
  setFieldValue,
}: {
  data?: PresetReportDto;
  values?: PresetReportDto;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsDomains: { [key: string]: string };
  templatesReport: PresetReportTemplateDto[];
  valuesParams: any;
  setValuesParams: any;
  setFieldValue: any;
}) => {
  const { t } = useTranslation();
  const { includeColumnHeaders } = data || {};

  const arrayParameters = useMemo(
    () =>
      templatesReport.find(
        (temp) =>
          Number(temp.presetReportTemplateId) === Number(values?.reportType)
      )?.presetReportTemplateParameters || [],
    [values?.reportType, templatesReport]
  );

  useEffect(() => {
    if (valuesParams.length > 0 && arrayParameters.length > 0) {
      valuesParams.forEach((element: any) => {
        const nameParam = arrayParameters.find(
          (ele) =>
            ele.presetReportTemplateParameterId ===
            element.presetReportTemplateParameterId
        )?.name;

        const typeField = arrayParameters.find(
          (ele) =>
            ele.presetReportTemplateParameterId ===
            element.presetReportTemplateParameterId
        )?.dataType;
        if (typeField === PresetReportTemplateParameterDataType.Boolean) {
          setFieldValue(nameParam, !!(element.value === 'True'));
        }
        if (typeField === PresetReportTemplateParameterDataType.Date) {
          setFieldValue(
            nameParam,
            moment(element.value).isValid() ? moment(element.value) : moment()
          );
        }
        if (
          typeField === PresetReportTemplateParameterDataType.Text ||
          typeField === PresetReportTemplateParameterDataType.Number
        ) {
          setFieldValue(nameParam, element.value);
        }
      });
    }
    if (arrayParameters.length > 0 && valuesParams.length === 0) {
      arrayParameters.forEach((element: any) => {
        setFieldValue(element.name, null);
      });
    }
  }, [valuesParams, arrayParameters]);

  const handleChangeParams = (
    event: React.ChangeEvent<{
      value: string;
    }>,
    params: PresetReportTemplateParameterModel
  ) => {
    let valueSetTarget = event.target.value;
    if (params.minimum && Number(valueSetTarget) < Number(params.minimum)) {
      valueSetTarget = params.minimum.toString();
    }
    const baseArray = valuesParams;
    const filterArray = baseArray.filter(
      (paramVal: any) =>
        paramVal.presetReportTemplateParameterId !==
        params.presetReportTemplateParameterId
    );

    setValuesParams([
      ...filterArray,
      {
        presetReportTemplateParameterId: params.presetReportTemplateParameterId,
        value: valueSetTarget,
      },
    ]);
    setFieldValue(params.name, valueSetTarget);
  };

  const handleChangeDate = (
    dateSel: moment.Moment,
    params: PresetReportTemplateParameterModel
  ) => {
    const valueSetTarget = dateSel;

    const baseArray = valuesParams;
    const filterArray = baseArray.filter(
      (paramVal: any) =>
        paramVal.presetReportTemplateParameterId !==
        params.presetReportTemplateParameterId
    );

    setValuesParams([
      ...filterArray,
      {
        presetReportTemplateParameterId: params.presetReportTemplateParameterId,
        value: valueSetTarget,
      },
    ]);
    setFieldValue(params.name, valueSetTarget);
  };

  const handleChangeBoolean = (
    event: React.ChangeEvent<{
      value: string;
    }>,
    params: PresetReportTemplateParameterModel
  ) => {
    const valueSetTarget = !!(event.target.value === 'true');

    const baseArray = valuesParams;
    const filterArray = baseArray.filter(
      (paramVal: any) =>
        paramVal.presetReportTemplateParameterId !==
        params.presetReportTemplateParameterId
    );

    setValuesParams([
      ...filterArray,
      {
        presetReportTemplateParameterId: params.presetReportTemplateParameterId,
        value: valueSetTarget ? 'True' : 'False',
      },
    ]);
    setFieldValue(params.name, valueSetTarget);
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item sm={4} xs={12}>
        <EditorSwitch
          label={
            <StyledDiv>
              {t('ui.reports.includecolumnheaders', 'Include Column Headers')}
              <StyledTooltip
                arrow
                placement="top"
                interactive
                title={`${t(
                  'ui.reports.headerstooltip',
                  'This option only applies to the export in CSV format. Exports in other formats will always show the column headers.'
                )}`}
              >
                <StyledInfoIcon />
              </StyledTooltip>
            </StyledDiv>
          }
          name="includeColumnHeaders"
          switchLabel={
            includeColumnHeaders
              ? t('ui.common.yes', 'Yes')
              : t('ui.common.no', 'No')
          }
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <EditorDropDown
          label={`${t('ui.reports.timezone', 'Time Zone')}*`}
          name="timeZoneId"
          nonSort
          isMandatory
          sortedArray={sortedTimeZoneArray}
          textMapping={optionsTimeZone}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <EditorDropDown
          label={`${t('ui.reports.atdomainname', '@DomainName')}*`}
          name="sprdomainId"
          nonSort
          isMandatory
          textMapping={optionsDomains}
        />
      </Grid>
      {arrayParameters.map((param) => {
        return (
          <Grid item sm={4} xs={12}>
            {param.dataType === PresetReportTemplateParameterDataType.Text && (
              <EditorTextBox
                label={`@${param.name}`}
                name={`${param.name}`}
                onInput={(
                  e: React.ChangeEvent<{
                    value: string;
                  }>
                ) => {
                  handleChangeParams(e, param);
                }}
              />
            )}
            {param.dataType ===
              PresetReportTemplateParameterDataType.Number && (
              <EditorTextBox
                label={`@${param.name}`}
                name={`${param.name}`}
                onInput={(
                  e: React.ChangeEvent<{
                    value: string;
                  }>
                ) => {
                  e.target.value = e.target.value.replace(/[^0-9.-]/, '');
                  if (param?.maximum && Number(e.target.value) > param?.maximum)
                    e.target.value = `${param?.maximum}`;
                }}
                onBlur={(
                  e: React.ChangeEvent<{
                    value: string;
                  }>
                ) => {
                  e.target.value = e.target.value.replace(/[^0-9.-]/, '');
                  handleChangeParams(e, param);
                }}
              />
            )}

            {param.dataType ===
              PresetReportTemplateParameterDataType.Boolean && (
              <EditorSwitch
                label={`@${param.name}`}
                name={`${param.name}`}
                // @ts-ignore
                value={!!(values && param.name && values[param.name])}
                onChange={(
                  e: React.ChangeEvent<{
                    value: string;
                  }>
                ) => handleChangeBoolean(e, param)}
                switchLabel={
                  // @ts-ignore
                  values && param.name && values[param.name]
                    ? t('ui.common.yes', 'Yes')
                    : t('ui.common.no', 'No')
                }
              />
            )}
            {param.dataType === PresetReportTemplateParameterDataType.Date && (
              <>
                <StyledFieldLabelText>{`@${param.name}`}</StyledFieldLabelText>
                <StyledKeyboardDatePickerLegacy
                  id={`${param.name}`}
                  KeyboardButtonProps={{ 'aria-label': 'change end date' }}
                  PopoverProps={{ id: `${param.name} popover` }}
                  // @ts-ignore
                  value={values && param.name ? values[param.name] : moment()}
                  onChange={(val) =>
                    handleChangeDate(val as moment.Moment, param)
                  }
                  style={{ width: '100%' }}
                />
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormProcedure;
