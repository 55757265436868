// Since the layout/design of this changes often, we keep some of the
// previously used styled components so they can be easily + re-used extremely
// quickly (for last minute changes) without comparing previously changes via
// git. This is why we disable the no-unused-vars check.
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CaretIcon } from 'assets/icons/caret.svg';
import RightShowcaseImage from 'assets/images/new-assets-navigators.png';
import Button from 'components/Button';
import UpdatedConfirmationDialog from 'components/dialog/UpdatedConfirmationDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectReleaseNotesRouteForCurrentApp } from 'redux-app/modules/app/selectors';
import styled from 'styled-components';

const StyledCaretIcon = styled(CaretIcon)`
  transform: rotate(-90deg);
`;

const StyledSubHeader = styled(Typography)`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  line-height: 28px;
`;

const StyledSubFooter = styled(Typography)`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  line-height: 28px;
  text-align: left;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const RoundedBox = styled.div`
  /*
    Increased top left and top right border radius since the provided images
    have a larger border radius
  */
  border-radius: 15px 15px 10px 10px;
  margin: 8px 0;
  background: #929292;
  overflow: hidden;
`;

const FeatureBox = styled.div`
  padding: 16px 16px 24px;
  background: #333;
`;

const FeatureDescription = styled(Typography)`
  color: #fff;
  font-size: 16px;
  text-align: left;
  line-height: 28px;
  font-weight: 400;

  span {
    font-weight: 600;
  }
`;

interface Props {
  isOpen?: boolean;
  handleClose: () => void;
}

const NewFeaturesShowcaseDialog = ({ isOpen, handleClose }: Props) => {
  const history = useHistory();
  const showReleaseNotes = false;
  const showImage = false;
  const { t } = useTranslation();
  const theme = useTheme();
  const releaseNotesRoute = useSelector(selectReleaseNotesRouteForCurrentApp);

  const closeDialog = () => {
    handleClose();
  };

  const confirmAndGoToReleaseNotes = () => {
    history.push(releaseNotesRoute);
    closeDialog();
  };

  const mainTitle = (
    <>
      {t(
        'ui.newFeaturesShowcaseDialog.titleupcoming',
        'Upcoming Server Migration'
      )}{' '}
    </>
  );

  return (
    <UpdatedConfirmationDialog
      open={!!isOpen}
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
      mainTitle={mainTitle}
      PaperProps={{
        style: {
          backgroundColor:
            theme.palette.type === 'dark' ? '#666666' : '#f8f8f8',
        },
      }}
      content={
        <>
          <Box p={1}>
            <Grid container spacing={3} alignItems="center" justify="center">
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <StyledSubHeader variant="h3" align="center">
                      {t(
                        'ui.newFeaturesShowcaseDialog.bodyupcoming',
                        'At Anova, we are committed to providing you with the best possible service, and as part of our continuous efforts to enhance stability, performance, and scalability, we are excited to announce an upcoming server upgrade.'
                      )}
                    </StyledSubHeader>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <RoundedBox>
                  <Grid container>
                    <Grid item xs={12}>
                      {showImage && <StyledImage src={RightShowcaseImage} />}

                      <FeatureBox>
                        {/*                     
                           // @ts-ignore */}
                        <FeatureDescription component="div">
                          <span>Phase 1: Database Migration</span>
                          <ul>
                            <li>
                              What: During this phase, we will be migrating our
                              databases to Microsoft Azure.
                            </li>
                            <li>
                              Date:<span>February, 3</span>
                            </li>
                            <li>Start Time: 8:00 AM EST</li>
                            <li>
                              Expected Downtime: Up to 4 hours (all systems:
                              Transcend, DOLV3, Field IG app and any
                              integrations)
                            </li>
                            <li>
                              No data will be lost. Historic data will be
                              restored during the migration.
                            </li>
                          </ul>
                          <br />

                          <span>Phase 2: App Servers Migration</span>
                          <ul>
                            <li>
                              What: During this phase, we will be migrating our
                              application servers to Microsoft Azure.
                            </li>
                            <li>
                              Date:<span>February, 10</span>
                            </li>
                            <li>Start Time: 8:00 AM EST</li>
                            <li>
                              Expected Downtime: Up to 6 hours (all systems:
                              Transcend, DOLV3, Field IG app and any
                              integrations)
                            </li>
                            <li>
                              No data will be lost. Historic data will be
                              restored during the migration.
                            </li>
                          </ul>
                        </FeatureDescription>
                      </FeatureBox>
                    </Grid>
                  </Grid>
                </RoundedBox>
              </Grid>
              <Grid item xs={11}>
                <StyledSubFooter variant="h3" align="center">
                  We understand the importance of our services to your
                  operations, and we apologize for any inconvenience these
                  temporary disruptions may cause. We have carefully chosen the
                  specified windows to minimize impact and ensure a smooth
                  transition. <br />
                  <br />
                  If you have any concerns or require further assistance, please
                  do not hesitate to contact our support team at
                  techsupport@anova.com. We appreciate your understanding and
                  cooperation as we work to improve our infrastructure and
                  provide you with an even more reliable service.
                  <br />
                  <br />
                  Thank you for being a valued part of the Anova community.
                </StyledSubFooter>
              </Grid>
              <Grid item xs={11}>
                <Box textAlign="center">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box textAlign="center">
                        <Button
                          variant="contained"
                          className="brand-yellow"
                          onClick={closeDialog}
                        >
                          {t(
                            'ui.welcome.startUsingTranscend',
                            'Start using Transcend'
                          )}
                        </Button>
                      </Box>
                    </Grid>
                    {showReleaseNotes && (
                      <Grid item xs={12}>
                        <Box textAlign="center">
                          <Button
                            variant="text"
                            onClick={confirmAndGoToReleaseNotes}
                            endIcon={<StyledCaretIcon />}
                          >
                            {t(
                              'ui.newFeaturesShowcaseDialog.viewReleaseNotes',
                              'View release notes'
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      hideConfirmationButton
      hideCancelButton
      onConfirm={closeDialog}
    />
  );
};

export default NewFeaturesShowcaseDialog;
