import { DataChannelType } from 'api/admin/api';
import { TFunction } from 'i18next';
import { buildDataChannelTypeTextMapping } from 'utils/i18n/enum-to-text';

export const numbersToDataChannelTypes = (
  includedDataChannelTypes?: string,
  t?: TFunction
): string => {
  const dataChannelTypeTextMapping = buildDataChannelTypeTextMapping(t!);

  if (!includedDataChannelTypes) return 'None';

  const types = includedDataChannelTypes.split(',');

  const typeNames = types.map((type) => {
    return dataChannelTypeTextMapping[+type as DataChannelType];
  });
  return typeNames.join(',');
};
