import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';

const retrieveCurrentUserAccessibleDomains = (includeAll: boolean) => {
  return ApiService.UserService.user_RetrieveCurrentUserAccessibleDomains(
    includeAll
  );
};

export const useRetrieveCurrentUserAccessibleDomains = (
  isEnabled: boolean = true,
  includeAll: boolean = false
) => {
  return useQuery(
    [APIQueryKey.retrieveCurrentUserAccessibleDomains],
    () => retrieveCurrentUserAccessibleDomains(includeAll),
    {
      enabled: isEnabled,
      cacheTime: 1000 * 60 * 2,
      staleTime: 1000 * 60 * 2,
    }
  );
};
