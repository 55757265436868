import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';

const retrieveAssetGroups = () => {
  return ApiService.DomainAssetGroupService.domainAssetGroup_RetrieveAssetGroupList();
};

const useGetAssetGroups = () => {
  return useQuery([APIQueryKey.retrieveAssetGroups], () =>
    retrieveAssetGroups()
  );
};

export default useGetAssetGroups;
