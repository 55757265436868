import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import BoxWithOverflowHidden from 'components/BoxWithOverflowHidden';
import CircularProgress from 'components/CircularProgress';
import TransitionErrorMessage from 'components/common/animations/TransitionErrorMessage';
import DarkFadeOverlay from 'components/DarkFadeOverlay';
import TableContainer from 'components/tables/components/TableContainer';
import TableCellCheckbox from 'components/forms/styled-fields/TableCellCheckbox';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, Cell, Column } from 'react-table';
import { PresetReportScheduleDto } from 'api/admin/api';
import PageSubHeader from 'components/PageSubHeader';
import Button from 'components/Button';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add-outlined.svg';
import { toggleAllSelectedRows, toggleOneSelectedRow } from 'utils/ui/deletion';
import styled from 'styled-components';
import GenericDataTable from 'components/GenericDataTable';
import {
  buildReportOutputCategoryTypeTextMapping,
  buildReportSendFrequencyTextMapping,
  buildReportSendCategoryTextMapping,
  buildReportOutputCategoryTypeBodyTextMapping,
} from 'utils/i18n/enum-to-text';
import { ScheduleTableId } from './helpers';
import EmptyScheduleTable from '../EmptyScheduleTable';

const ActionButton = styled(Button)`
  padding: 0px;
  margin-left: 4px;
`;

interface SchedulesTableData extends PresetReportScheduleDto {
  isSelected?: boolean;
}

const SessionQueueTable = ({
  isSuccessDeletion,
  isLoadingDeletion,
  data,
  canUpdateReport,
  canAddSchedule,
  isFetching,
  error,
  handleDeleteSchedule,
  handleAddSchedule,
}: {
  data: PresetReportScheduleDto[];
  canUpdateReport: boolean;
  canAddSchedule: boolean;
  isFetching?: boolean;
  error: boolean;
  isSuccessDeletion: boolean;
  isLoadingDeletion: boolean;
  handleDeleteSchedule: (scheduleId: string[]) => void;
  handleAddSchedule: () => void;
}) => {
  const { t } = useTranslation();

  const OutputCategoryTypeOp = buildReportOutputCategoryTypeTextMapping(t);
  const OutputCategoryTypeBodyOp = buildReportOutputCategoryTypeBodyTextMapping(
    t
  );
  const OutputCategoryAll = {
    ...OutputCategoryTypeOp,
    ...OutputCategoryTypeBodyOp,
  };
  const ReportSendFrequencyTypeOp = buildReportSendFrequencyTextMapping(t);
  const ReportSendCategoryOp = buildReportSendCategoryTextMapping(t);

  const [selectedRows, setSelectedRows] = useState<
    Record<string, SchedulesTableData>
  >({});

  useEffect(() => {
    if (isSuccessDeletion) {
      setSelectedRows({});
    }
  }, [isSuccessDeletion]);

  const handleDelete = () => {
    const schedulesIdsToBeDeleted = Object.keys(selectedRows);
    handleDeleteSchedule(schedulesIdsToBeDeleted);
  };

  const columns: Column<SchedulesTableData>[] = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ rows: _rows }) => {
          const selectableRows = _rows.filter(
            (row) => row.original.presetReportScheduleId
          );
          const areAllRowsSelected =
            !!selectableRows.length &&
            selectableRows.every(
              (row) => selectedRows[row.original.presetReportScheduleId!]
            );
          return (
            <TableCellCheckbox
              onChange={() => {
                const newSelectedRows = toggleAllSelectedRows(
                  selectedRows,
                  selectableRows,
                  'presetReportScheduleId'
                );
                setSelectedRows(newSelectedRows);
              }}
              checked={areAllRowsSelected}
              disabled={!selectableRows.length}
            />
          );
        },
        accessor: ScheduleTableId.IsSelected,
        Cell: ({ row }) => {
          return (
            <TableCellCheckbox
              onChange={() => {
                setSelectedRows((prevSelectedRows) => {
                  const newSelectedRows = toggleOneSelectedRow(
                    prevSelectedRows,
                    row,
                    'presetReportScheduleId'
                  );
                  return newSelectedRows;
                });
              }}
              checked={
                !!selectedRows[row.original.presetReportScheduleId!] || false
              }
            />
          );
        },
      },
      {
        id: ScheduleTableId.ReportOutputType,
        Header: t('ui.reports.outputtype', 'Output type') as string,
        accessor: ScheduleTableId.ReportOutputType,
        Cell: (cell: Cell) => {
          const cellValue = cell.value;
          return (
            OutputCategoryAll[cellValue as keyof typeof OutputCategoryAll] ||
            OutputCategoryAll[cellValue as keyof typeof OutputCategoryAll]
          );
        },
      },
      {
        id: ScheduleTableId.ReportSendType,
        Header: t('ui.reports.sendtype', 'Send Type') as string,
        accessor: ScheduleTableId.ReportSendType,
        Cell: (cell: Cell) => {
          const cellValue = cell.value;
          return ReportSendCategoryOp[
            cellValue as keyof typeof ReportSendCategoryOp
          ];
        },
      },
      {
        id: ScheduleTableId.ReportSendFrequencyType,
        Header: t('ui.reports.frequency', 'Frequency') as string,
        accessor: ScheduleTableId.ReportSendFrequencyType,
        Cell: (cell: Cell) => {
          const cellValue = cell.value;
          return ReportSendFrequencyTypeOp[
            cellValue as keyof typeof ReportSendFrequencyTypeOp
          ];
        },
      },
      {
        id: ScheduleTableId.ReportSendTime,
        Header: t('ui.reports.sendat', 'Send at') as string,
        accessor: ScheduleTableId.ReportSendTime,
      },
      {
        id: ScheduleTableId.SendToAddressList,
        Header: t('ui.reports.to', 'To') as string,
        accessor: ScheduleTableId.SendToAddressList,
      },
      {
        id: ScheduleTableId.SendSubject,
        Header: t('ui.reports.subject', 'Subject') as string,
        accessor: ScheduleTableId.SendSubject,
      },
    ],
    [data, selectedRows]
  );
  const tableInstance = useTable<SchedulesTableData>({
    columns,
    data,
  });

  const shouldDisplayTable = !error && !isFetching && !isLoadingDeletion;

  return (
    <Box height="100%" display="flex" flexDirection="column" flexGrow={1}>
      <TransitionErrorMessage in={!!error} />

      {(isFetching || isLoadingDeletion) && (
        <Box textAlign="center" my={3}>
          <CircularProgress />
        </Box>
      )}

      {shouldDisplayTable && (
        <>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={8}>
              <PageSubHeader style={{ marginBottom: '4px' }}>
                {t('ui.reports.schedules', 'Schedules')}
              </PageSubHeader>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '32px',
              }}
            >
              {canAddSchedule && (
                <ActionButton
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={handleAddSchedule}
                >
                  {t('ui.reports.addschedule', 'Add Schedule')}
                </ActionButton>
              )}
              {canUpdateReport && data?.length > 0 && (
                <ActionButton
                  variant="text"
                  startIcon={<DeleteIcon />}
                  disabled={Object.keys(selectedRows).length === 0}
                  onClick={handleDelete}
                >
                  {t('ui.common.deleteselected', 'Delete Selected')}
                </ActionButton>
              )}
            </Grid>
          </Grid>

          {data?.length > 0 && (
            <BoxWithOverflowHidden
              pb={3}
              px={2}
              mt={3}
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <DarkFadeOverlay darken={isFetching} height="100%">
                <TableContainer
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    display: 'inline-block',
                  }}
                >
                  <GenericDataTable<SchedulesTableData>
                    tableInstance={tableInstance}
                    disableActions={false}
                    tableAriaLabelText="Schedule Info"
                    isRecordDisabled={() => false}
                    columnIdToAriaLabel={(id) => id}
                    getColumnWidth={() => 100}
                    handleRowClick={() => {}}
                    minWidth={1300}
                  />
                </TableContainer>
              </DarkFadeOverlay>
            </BoxWithOverflowHidden>
          )}
          {data?.length === 0 && <EmptyScheduleTable />}
        </>
      )}
    </Box>
  );
};

export default SessionQueueTable;
