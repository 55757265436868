/* eslint-disable indent */
import Drawer from 'components/drawers/Drawer';
import Typography from '@material-ui/core/Typography';
import DrawerContent from 'components/drawers/DrawerContent';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import React, { useState } from 'react';
import CustomThemeProvider from 'components/CustomThemeProvider';
import PageIntroWrapper from 'components/layout/PageIntroWrapper';
import EditorPageIntro from 'components/EditorPageIntro';
import ChipDomain from 'components/ChipDomain';
import {
  ErrorRecordResponseModel,
  PresetReportScheduleDto,
  UserNameDto,
  ReportSendCategory,
} from 'api/admin/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import uniq from 'lodash/uniq';
import CustomTextField from 'components/forms/form-fields/CustomTextField';
import DropdownAutocomplete from 'components/forms/styled-fields/DropdownAutocomplete';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import EditorBox from 'components/EditorBox';
import { useQueryClient } from 'react-query';
import { APIQueryKey } from 'api/react-query/helpers';
import { enqueueSaveSuccessSnackbar } from 'redux-app/modules/app/actions';
import {
  buildReportOutputCategoryTypeTextMapping,
  buildReportOutputCategoryTypeBodyTextMapping,
  buildReportSendFrequencyTextMapping,
  buildReportSendCategoryTextMapping,
} from 'utils/i18n/enum-to-text';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import useAddSchedule from '../../hooks/useAddSchedule';
import {
  EditorDropDown,
  EditorTextBox,
  EditorTime,
} from '../common/EditorFields';
import { StyledFieldLabelText } from '../common/EditorFields/styles';
import { fieldIsRequired } from '../../../../utils/forms/errors';

const filter = createFilterOptions<any>();
type AddScheduleDrawerProps = {
  reportId: number;
  reportName: string;
  isOpen?: boolean;
  onClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
  userEmailList?: UserNameDto[] | null;
};

const getTimeInStringFromDate = (date: Date | undefined) => {
  if (!date) return null;
  const convertedDate = new Date(date);
  const hour =
    convertedDate.getHours() > 9
      ? `${convertedDate.getHours()}`
      : `0${convertedDate.getHours()}`;
  const minutes =
    convertedDate.getMinutes() > 9
      ? `${convertedDate.getMinutes()}`
      : `0${convertedDate.getMinutes()}`;
  const stringHour = `${hour}:${minutes}`;
  // eslint-disable-next-line no-console
  console.log('stringHour', stringHour);
  return stringHour;
};

const buildValidationSchema = (
  t: TFunction,
  translationTexts: Record<string, string>
) => {
  return Yup.object().shape({
    sendSubject: Yup.string()
      .typeError(fieldIsRequired(t, translationTexts.sendSubjectText))
      .required(fieldIsRequired(t, translationTexts.sendSubjectText)),
    reportSendType: Yup.number()
      .typeError(fieldIsRequired(t, translationTexts.reportSendTypeText))
      .required(fieldIsRequired(t, translationTexts.reportSendTypeText)),
    reportOutputType: Yup.number()
      .typeError(fieldIsRequired(t, translationTexts.reportOutputTypeText))
      .required(fieldIsRequired(t, translationTexts.reportOutputTypeText)),
  });
};

const AddScheduleDrawer = ({
  reportId,
  reportName,
  isOpen,
  setIsOpen,
  onClose,
  userEmailList,
}: AddScheduleDrawerProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  // @ts-ignore
  const data: PresetReportScheduleDto = {
    presetReportId: reportId,
    reportOutputType: 1,
    reportSendType: 2,
    reportSendTime: '',
    reportSendFrequencyType: 1,
    sendToAddressList: null,
    sendSubject: reportName,
    sendMessage: '',
  };

  const [formErrors, setFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleErrorsOnSave = (errors: ErrorRecordResponseModel[]) => {
    const errorsMap: { [key: string]: string } = {};

    for (let i = 0; i < errors?.length; i++) {
      if (errors?.[i] && errors?.[i]?.propertyName) {
        const newPropertyName = errors?.[i]?.propertyName || '';
        errorsMap[newPropertyName] = errors?.[i]?.errorMessage || '';
      }
    }

    setFormErrors(errorsMap);
  };

  const OutputCategoryTypeOp = buildReportOutputCategoryTypeTextMapping(t);
  const OutputCategoryTypeBodyOp = buildReportOutputCategoryTypeBodyTextMapping(
    t
  );
  const ReportSendFrequencyTypeOp = buildReportSendFrequencyTextMapping(t);
  const ReportSendCategoryOp = buildReportSendCategoryTextMapping(t);

  const addSchedule = useAddSchedule(reportId);

  const [commTime, setCommTime] = useState<Date>(new Date());
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const cancelCallback = () => {
    setIsOpen(false);
    setSelectedEmails([]);
    addSchedule.reset();
  };

  const saveAndExitCallback = () => {};

  const handleDeleteEmail = (emailData: string) => {
    const filterSelectedEmail = selectedEmails.filter(
      (val) => val !== emailData
    );
    setSelectedEmails(uniq(filterSelectedEmail));
  };

  const sendSubjectText = t('ui.reports.subject', 'Subject');
  const reportSendTypeText = t('ui.reports.sendtype', 'Send Type');
  const reportOutputTypeText = t('ui.reports.outputtype', 'Output type');
  const reportSendFrequencyTypeText = t('ui.reports.frequency', 'Frequency');

  const validationSchema = buildValidationSchema(t, {
    sendSubjectText,
    reportSendTypeText,
    reportOutputTypeText,
    reportSendFrequencyTypeText,
  });

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} variant="temporary">
      <DrawerContent>
        <Formik<PresetReportScheduleDto>
          initialValues={data!}
          validationSchema={validationSchema}
          onSubmit={(values: PresetReportScheduleDto, actions) => {
            addSchedule.reset();
            addSchedule.mutate(
              ({
                ...values,
                reportSendTime: getTimeInStringFromDate(commTime),
                sendToAddressList: selectedEmails.join(';'),
              } as unknown) as PresetReportScheduleDto,
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    APIQueryKey.retrieveReportSchedules,
                    reportId,
                  ]);
                  setSelectedEmails([]);
                  setIsOpen(false);
                  dispatch(enqueueSaveSuccessSnackbar(t));
                },
                onSettled: () => {
                  actions.setSubmitting(false);
                },
                onError: (errors: any) => {
                  handleErrorsOnSave(errors);
                },
              }
            );
          }}
          enableReinitialize
        >
          {({ isSubmitting, submitForm, values, setFieldValue }) => {
            return (
              <Form>
                <CustomThemeProvider forceThemeType="dark">
                  <PageIntroWrapper sticky isWithinDrawer topOffset={0}>
                    <EditorPageIntro
                      showSaveOptions
                      title={t('ui.reports.addschedule', 'Add Schedule')}
                      cancelCallback={cancelCallback}
                      submitForm={submitForm}
                      isSubmitting={isSubmitting}
                      submissionResult={addSchedule.data}
                      submissionError={addSchedule.error}
                      saveAndExitCallback={saveAndExitCallback}
                    />
                  </PageIntroWrapper>
                </CustomThemeProvider>
                <Box mt={3} />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <EditorBox>
                      <Grid container spacing={4} alignItems="center">
                        <EditorDropDown
                          label={`${t('ui.reports.sendtype', 'Send Type')}*`}
                          name="reportSendType"
                          alignment="horizontal"
                          isMandatory
                          selectprops={{
                            onChange: (e: any) => {
                              setFieldValue('reportOutputType', null);
                              setFieldValue('reportSendType', e.target.value);
                            },
                          }}
                          textMapping={ReportSendCategoryOp}
                        />
                        <EditorDropDown
                          label={`${t(
                            'ui.reports.outputtype',
                            'Output type'
                          )}*`}
                          name="reportOutputType"
                          alignment="horizontal"
                          isMandatory
                          textMapping={
                            Number(values.reportSendType) ===
                            ReportSendCategory.EmailReportInBody
                              ? OutputCategoryTypeBodyOp
                              : OutputCategoryTypeOp
                          }
                        />
                        <EditorDropDown
                          label={`${t('ui.reports.frequency', 'Frequency')}*`}
                          name="reportSendFrequencyType"
                          alignment="horizontal"
                          nonSort
                          isMandatory
                          textMapping={ReportSendFrequencyTypeOp}
                        />

                        <EditorTime
                          label={`${t('ui.reports.sendat', 'Send at')}*`}
                          name="reportSendTime"
                          alignment="horizontal"
                          style={{ width: '200px', display: 'block' }}
                          value={commTime}
                          onChange={setCommTime}
                          error={!!formErrors?.ReportSendTime}
                          errorMessage={formErrors?.ReportSendTime}
                        />

                        <Grid item xs={4}>
                          <StyledFieldLabelText>
                            {`${t('ui.reports.to', 'To')}*`}
                          </StyledFieldLabelText>
                        </Grid>
                        <Grid item xs={8}>
                          <DropdownAutocomplete<UserNameDto>
                            options={userEmailList || []}
                            value={null}
                            getOptionLabel={(option) =>
                              option?.emailAddress || ''
                            }
                            inputValue={inputValue}
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              const target = e.target as HTMLTextAreaElement;
                              if (e.key === 'Enter') {
                                const selEmail = target.value
                                  ? [...selectedEmails, target.value]
                                  : selectedEmails;
                                setSelectedEmails(uniq(selEmail));
                                setInputValue('');
                              }
                            }}
                            onChange={(__: any, email) => {
                              if (email?.emailAddress?.includes('Add')) {
                                const selEmail = [
                                  ...selectedEmails,
                                  email?.emailAddress.split(' ')[1],
                                ];
                                setSelectedEmails(uniq(selEmail));
                                setInputValue('');
                              } else {
                                const selEmail = email?.emailAddress
                                  ? [...selectedEmails, email?.emailAddress]
                                  : selectedEmails;
                                setSelectedEmails(uniq(selEmail));
                                setInputValue('');
                              }
                            }}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            renderOption={(option) =>
                              option.firstName === '' ? (
                                <Typography>{`${option.emailAddress}`}</Typography>
                              ) : (
                                <Typography
                                  style={{
                                    lineHeight: '100%',
                                  }}
                                >
                                  {`${option.emailAddress} (${option.firstName} ${option.lastName})`}
                                </Typography>
                              )
                            }
                            fieldError={formErrors.SendToAddressList}
                            freeSolo
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue: inputVal } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputVal === option.emailAddress
                              );
                              if (inputVal !== '' && !isExisting) {
                                filtered.push({
                                  inputVal,
                                  emailAddress: `Add ${inputVal}`,
                                  firstName: '',
                                  lastName: '',
                                });
                              }

                              return filtered;
                            }}
                          />
                        </Grid>

                        {selectedEmails && selectedEmails?.length > 0 && (
                          <Grid item xs={12} style={{ padding: '0px 8px' }}>
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              justify="flex-start"
                            >
                              {selectedEmails?.map((email, indx) => {
                                return (
                                  <>
                                    <Grid item key={indx}>
                                      <ChipDomain
                                        label={email}
                                        onDelete={() =>
                                          handleDeleteEmail(email!)
                                        }
                                      />
                                    </Grid>
                                  </>
                                );
                              })}
                            </Grid>
                          </Grid>
                        )}

                        <EditorTextBox
                          label={`${t('ui.reports.subject', 'Subject')}*`}
                          name="sendSubject"
                          alignment="horizontal"
                        />

                        <Grid item xs={12}>
                          <Field
                            id="body-input"
                            name="sendMessage"
                            label={t('ui.reports.body', 'Body')}
                            multiline
                            rows={12}
                            component={CustomTextField}
                            disabled={isSubmitting}
                            inputProps={{ maxLength: 8000 }}
                          />
                        </Grid>
                      </Grid>
                    </EditorBox>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DrawerContent>
    </Drawer>
  );
};

export default AddScheduleDrawer;
