export enum ScheduleTableId {
  PresetReportScheduleId = 'presetReportScheduleId',
  PresetReportId = 'presetReportId',
  CreatedDate = 'createdDate',
  ReportOutputType = 'reportOutputType',
  ReportSendDay = 'reportSendDay',
  ReportSendFrequencyType = 'reportSendFrequencyType',
  ReportSendTime = 'reportSendTime',
  ReportSendType = 'reportSendType',
  SendMessage = 'sendMessage',
  SendSubject = 'sendSubject',
  SendToAddressList = 'sendToAddressList',

  IsSelected = 'isSelected',
}
