import { ReportType } from 'api/admin/api';

export type OrderInfo = {
  header: string;
  key: string;
  func?: (value?: any) => string;
};
type PdfColumnSettings = {
  titleRow?: string;
  order: OrderInfo[];
  initSize: number[];
  paperSize: 'A2' | 'A3' | 'A4';
};
export const PdfReportColumns = (
  reportType?: ReportType
): PdfColumnSettings | null => {
  if (!reportType) {
    return null;
  }

  switch (reportType) {
    case ReportType.LowBatteryVoltage:
      return {
        titleRow: 'assetTitle',
        order: [
          { header: 'Domain', key: 'domainName' },
          { header: 'Description', key: 'dataChannelDescription' },
          { header: 'Customer', key: 'customerName' },
          { header: 'RTU', key: 'deviceId' },
          { header: 'Channel', key: 'channelNumber' },
          { header: 'Carrier', key: 'carrier' },
          { header: 'Product', key: 'productName' },
          { header: 'Last Data Received', key: 'lastReadingTimestamp' },
          { header: 'Last Battery Voltage', key: 'latestBatteryVolts' },
          { header: 'Battery Low', key: 'batteryLow' },
        ],
        initSize: [40, 30, 30, 30, 30, 30, 30, 30, 60, 30],
        paperSize: 'A4',
      };
    case ReportType.DataChannelsWithMissingReadings:
      return {
        titleRow: 'assetTitle',
        order: [
          { header: 'Domain', key: 'domainName' },
          { header: 'Description', key: 'dataChannelDescription' },
          { header: 'Customer', key: 'customerName' },
          { header: 'Address', key: 'address1' },
          { header: 'City', key: 'city' },
          { header: 'State', key: 'state' },
          { header: 'Country', key: 'country' },
          { header: 'RTU', key: 'deviceId' },
          { header: 'Channel', key: 'channelNumber' },
          { header: 'Carrier', key: 'carrier' },
          { header: 'Product', key: 'productName' },
          { header: 'Last Date Received', key: 'lastReadingTimestamp' },
          { header: 'Expected Date On', key: 'lastReadingExpectedTimestamp' },
          { header: 'Delinquent Period', key: 'delinquentPeriodAsText' },
        ],
        initSize: [40, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30],
        paperSize: 'A3',
      };
    case ReportType.NewAssetList:
      return {
        titleRow: 'assetTitle',
        order: [
          { header: 'Item', key: 'dataChannelDescription' },
          { header: 'Reading Time', key: 'readingTime' },
          {
            header: 'Reading',
            key: 'reading',
            func: (data?: any) => {
              if (data?.readingValue) {
                return `${data?.readingValue?.toFixed(2)} ${
                  data?.displayUnits || data?.scaledUnitsAsText
                }`;
              }
              return '';
            },
          },
          {
            header: '% Full',
            key: 'percentFull',
            func: (data?: any) =>
              data?.percentFull ? `${data?.percentFull}%` : '',
          },
          { header: 'Product', key: 'productDescription' },
          {
            header: 'Inventory State',
            key: 'inventoryState',
            func: (data?: any) =>
              data?.inventoryState ? data?.inventoryState : 'Normal',
          },
          { header: 'Importance Level', key: 'eventImportanceLevelId' },
          { header: 'Importance', key: 'Importance' },
          { header: 'Status', key: 'eventStatus' },
          { header: 'Scheduled Refill', key: 'scheduledRefill' },
          { header: 'Alarm Level', key: 'alarmLevel' },
          {
            header: 'Deliverable',
            key: 'deliverable',
            func: (data?: any) =>
              data?.deliverable ? `${data?.deliverable?.toFixed(2)}` : '',
          },
          { header: 'RTU', key: 'rtuDeviceId' },
          { header: 'FTP ID', key: 'ftpId1' },
          { header: 'First Reading', key: 'firstReadingDate' },
          { header: 'Date Created', key: 'dataChannelCreatedDate' },
        ],
        initSize: [
          40,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
        ],
        paperSize: 'A3',
      };
    case ReportType.AssetList:
      return {
        titleRow: 'assetTitle',
        order: [
          { header: 'Description', key: 'assetDescription' },
          { header: 'Customer', key: 'customerName' },
          { header: 'RTU', key: 'deviceId' },
          {
            header: 'Site Info',
            key: 'siteInfo',
            func: (data?: any) => {
              const addressParts: string[] = [
                data?.address1,
                data?.address2,
                data?.address3,
                data?.city,
                data?.state,
              ];
              return addressParts
                .filter((part) => part && part.trim().length > 0)
                .join(', ');
            },
          },
          { header: 'Product', key: 'productName' },
          { header: 'Data Channels', key: 'dataChannelCount' },
        ],
        initSize: [30, 30, 30, 45, 30, 30],
        paperSize: 'A4',
      };
    case ReportType.HornerDOEReport:
      return {
        order: [
          { header: 'Location', key: 'location' },
          { header: 'RTU', key: 'rtuDescription' },
          { header: 'Start Date Time', key: 'fillStartTime' },
          { header: 'Start Hose Pressure (MPa)', key: 'startHosePressure' },
          { header: 'Start Tank Pressure (MPa)', key: 'startTankPressure' },
          {
            header: 'Start Tank Temperature (°C)',
            key: 'startTankTemperature',
          },
          { header: 'Company Name', key: 'companyName' },
          { header: 'Company User Id', key: 'userNo' },
          { header: 'Ambient Temperature (°C)', key: 'ambientTemperature' },
          {
            header: 'Fill Duration (Seconds)',
            key: 'duration',
            func: (value) => {
              return value?.duration?.toFixed(2) || '-';
            },
          },
          { header: 'Flow (Kgs)', key: 'flow' },
          { header: 'Average Fill Rate (Kg/min)', key: 'averageFillRate' },
          { header: 'Fuel Temperature (°C)', key: 'fuelTemperature' },
          { header: 'End Date Time', key: 'fillEndTime' },
          { header: 'End Hose Pressure (MPa)', key: 'endPressure' },
          { header: 'End Tank Pressure (MPa)', key: 'endTankPressure' },
          { header: 'End Tank Temperature (°C)', key: 'endTankTemperature' },
          { header: 'Product Code', key: 'product' },
          { header: 'Blend Percent (%)', key: 'blend' },
          { header: 'Shutdown Reason', key: 'shutdownReason' },
          { header: 'Comm Type', key: 'commType' },
          { header: 'Comm Loss Count', key: 'commLossCount' },
          { header: 'Non Comm Reason', key: 'nonCommReason' },
          { header: 'Vehicle Id', key: 'vechicleId' },
          { header: 'Vehicle Odometer (Km)', key: 'odometerReading' },
        ],
        initSize: [
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
          30,
        ],
        paperSize: 'A3',
      };
    default:
      return null;
  }
};
