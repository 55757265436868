import { useTranslation } from 'react-i18next';
import adminRoutes from 'apps/admin/routes';
import freezersRoutes from 'apps/freezers/routes';
import systemRoutes from 'apps/system/routes';
import OperationsTopNav from 'apps/ops/components/navigation/TopNavigation';
import opsRoutes from 'apps/ops/routes';
import reportsRoutes from 'apps/reports/routes';
import TrainingTopNav from 'apps/training/components/navigation/TopNavigation';
import trainingRoutes from 'apps/training/routes';
import CustomThemeProvider from 'components/CustomThemeProvider';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TopNavigation = () => {
  const { t } = useTranslation();
  return (
    <CustomThemeProvider forceThemeType="dark">
      <Switch>
        <Route path={trainingRoutes.base}>
          <TrainingTopNav />
        </Route>
        <Route path={adminRoutes.base}>
          <OperationsTopNav
            menuOption="Admin"
            appName={t('ui.main.administration', 'Administration')}
          />
        </Route>
        <Route path={reportsRoutes.base}>
          <OperationsTopNav
            menuOption="Reports"
            appName={t('ui.main.reports', 'Reports')}
          />
        </Route>
        <Route path={freezersRoutes.base}>
          <OperationsTopNav
            menuOption="Freezers"
            appName={t('ui.main.freezers', 'Freezers')}
          />
        </Route>
        <Route path={systemRoutes.base}>
          <OperationsTopNav
            menuOption="System"
            appName={t('ui.main.system', 'System')}
          />
        </Route>
        <Route path={opsRoutes.base}>
          <OperationsTopNav
            menuOption="Ops"
            appName={t('ui.main.operations', 'Operations')}
          />
        </Route>
      </Switch>
    </CustomThemeProvider>
  );
};

export default TopNavigation;
