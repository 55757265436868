import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from 'apps/ops/components/navigation/BreadcrumbBar/components/PdfDocument';
import Button from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import DialogContent from 'components/dialog/DialogContent';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isReportBuilt } from 'utils/functions/isReportBuilt';

const StyledTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

const StyledBannerBox = styled(Box)`
  border-radius: ${(props) =>
    `${props.theme.shape.borderRadius}px ${props.theme.shape.borderRadius}px 0 0`};

  background-color: ${(props) =>
    props.theme.palette.type === 'light' ? '#333333' : '#515151'};
`;

const DialogBanner = (props: React.ComponentProps<typeof Box>) => (
  <StyledBannerBox
    minHeight="28px"
    color="white"
    py={1}
    px={2}
    {...props}
    display="flex"
    alignItems="center"
  />
);

const StyledContentText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.5%;
  color: ${(props) => props.theme.palette.text.primary};
`;

type ReportFileType = 'pdf' | 'csv' | 'exl';

interface QuickReportDownloadDialogProps {
  open: boolean;
  hasError?: boolean;
  handleClose: () => void;
  title: string;
  onCreateReport?: (reportFileType: ReportFileType, reportId?: number) => void;
  reportId?: number;
  data?: any;
  error?: any;
}

const QuickReportDownloadDialog = ({
  open,
  handleClose,
  title,
  onCreateReport,
  reportId,
  data,
  error,
}: QuickReportDownloadDialogProps) => {
  const { t } = useTranslation();
  const enablePdfOption = false;
  const [startDownload, setStartDownload] = React.useState(false);
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
        setStartDownload(false);
      }}
    >
      <Grid item xs={12}>
        <DialogBanner justifyContent="center">
          <StyledTitle align="center">{title}</StyledTitle>
        </DialogBanner>
      </Grid>

      <Grid>
        <DialogContent minHeight="30px" padding="10px">
          <StyledContentText>
            {t(
              'ui.reporting.selectreportformattodownload',
              'Select report format to download:'
            )}
          </StyledContentText>
          <Box textAlign="center" padding={1}>
            {!data && !error && <CircularProgress />}
          </Box>
        </DialogContent>
      </Grid>
      <Grid item>
        {startDownload && !error && (
          <DialogActions style={{ justifyContent: 'center' }}>
            <Suspense fallback={<CircularProgress />}>
              <PDFDownloadLink
                document={<PdfDocument data={data} />}
                fileName="report.pdf"
              >
                {({ blob, loading }) =>
                  loading || !blob ? 'Building Report...' : 'Download now!'
                }
              </PDFDownloadLink>
            </Suspense>
          </DialogActions>
        )}
        {error && (
          <DialogActions style={{ justifyContent: 'center' }}>
            {error.message}
          </DialogActions>
        )}
        {!startDownload && !error && (
          <DialogActions style={{ justifyContent: 'center' }}>
            {enablePdfOption && (
              <Button
                variant="outlined"
                disabled={!data || !isReportBuilt(data)}
                onClick={() => {
                  setStartDownload(true);
                }}
              >
                {t('ui.common.pdf', 'PDF')}
              </Button>
            )}

            <Button
              variant="outlined"
              onClick={() => {
                onCreateReport?.('exl', reportId);
              }}
              disabled={!data || !isReportBuilt(data)}
            >
              {t('ui.common.excel', 'EXCEL')}
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                onCreateReport?.('csv', reportId);
              }}
              disabled={!data || !isReportBuilt(data)}
            >
              {t('ui.common.csv', 'CSV')}
            </Button>
          </DialogActions>
        )}
      </Grid>
    </Dialog>
  );
};
export default QuickReportDownloadDialog;
