import { ApiService } from 'api/admin/ApiService';
import { useMutation } from 'react-query';

const useDeleteSchedules = (presetReportId: number) => {
  return useMutation((reportScheduleId: number) => {
    return ApiService.PresetReportService.presetReport_DeleteSchedule(
      presetReportId,
      reportScheduleId
    );
  });
};
export default useDeleteSchedules;
