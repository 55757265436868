import React, { useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next/';
import Popover from '@material-ui/core/Popover';
import { selectActiveDomain } from 'redux-app/modules/app/selectors';
import { selectUserId } from 'redux-app/modules/user/selectors';
import { useSelector } from 'react-redux';
import {
  useRetrievePresetReports,
  OptionsProps,
} from 'apps/reports/hooks/useRetrievePresetReports';
import { PresetReportDto, ReportType } from 'api/admin/api';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { isReportBuilt } from 'utils/functions/isReportBuilt';
import {
  StyledBreadcrumbCaret,
  StyledButton,
  StyledText,
  StyledGrid,
} from './styles';

type QuickReportsDropdownProps = {
  onReportClicked: (presetReportId?: number | null) => void;
};
const QuickReportsDropdown = ({
  onReportClicked,
}: QuickReportsDropdownProps) => {
  const { t } = useTranslation();

  const [anchorElement, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [textFilter, setTextFilter] = useState<string>('');

  const activeDomain = useSelector(selectActiveDomain);
  const userId = useSelector(selectUserId);
  const domainId = activeDomain?.domainId || '';

  const open = Boolean(anchorElement);
  const id = open ? 'nav-item-popover' : undefined;

  const getRtuPollScheduleGroupRecordsApiOptions = () => {
    return {
      isCountRequired: true,
      pageNumber: 0,
      pageSize: 100,
      filterText: textFilter,
      domainId,
      userId,
    };
  };

  const [reportsList, setReportsList] = useState<PresetReportDto[] | null>(
    null
  );

  const options = getRtuPollScheduleGroupRecordsApiOptions();
  const { isSuccess, refetch } = useRetrievePresetReports(
    (options as unknown) as OptionsProps,
    {
      keepPreviousData: true,
      onSuccess: (apiData: any) => {
        setReportsList(apiData?.result || null);
      },
    }
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'Backspace' || key === 'Delete') {
      setTextFilter(textFilter.slice(0, -1));
      refetch();
      return;
    }

    refetch();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTextFilter('');
    refetch();
  };

  // put some code to download report here
  const onReportClick = (presetReportId?: number | null) => {
    setAnchorEl(null);
    onReportClicked(presetReportId);
  };

  const renderList = (list: PresetReportDto[]) => {
    if (textFilter.length > 0 && reportsList?.length === 0) {
      return (
        <Box p={2}>
          {`${t(
            'ui.reports.noreportsforfilter',
            'No Reports for current filter'
          )}: ${textFilter}`}
        </Box>
      );
    }
    if (list.length === 0) {
      return <Box p={2}>{t('ui.reports.empty', 'No Reports Setup')}</Box>;
    }
    return list.map((item: PresetReportDto) => {
      if (
        isReportBuilt({
          quickReportType: item.presetReportTemplateId as ReportType,
        })
      ) {
        return (
          <StyledGrid
            item
            onClick={() => onReportClick(item.presetReportId)}
            key={item.presetReportId}
          >
            {item.name}
          </StyledGrid>
        );
      }

      return (
        <StyledGrid item key={item.presetReportId} style={{ color: 'gray' }}>
          {item.name}
        </StyledGrid>
      );
    });
  };

  return (
    <>
      <StyledButton onClick={handleClick} aria-label="Open quick reports">
        <StyledText>{t('ui.main.quickreport', 'Quick Report')}</StyledText>
        <StyledBreadcrumbCaret />
      </StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { width: 425 } }}
        transitionDuration={0}
        onKeyDown={handleKeyDown}
      >
        <Grid container direction="column">
          {isSuccess && renderList(reportsList || [])}
        </Grid>
      </Popover>
    </>
  );
};

export default QuickReportsDropdown;
