import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';
import { EvolveGetDataChannelsByDomainIdAndUserIdRequest } from 'api/admin/api';

const retrieveDataChannels = (domainId: string, userId: string) => {
  return ApiService.DataChannelService.getDataChannelsByDomainIdAndUserId_GetDataChannelsByDomainIdAndUserId(
    { domainId, userId } as EvolveGetDataChannelsByDomainIdAndUserIdRequest
  );
};

const useGetDataChannels = (domainId: string, userId: string) => {
  return useQuery([APIQueryKey.retrieveDataChannels, domainId, userId], () =>
    retrieveDataChannels(domainId!, userId!)
  );
};

export default useGetDataChannels;
