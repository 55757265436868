import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';

const retrieveCarriers = (selectedDomainId?: string | null | undefined) => {
  return ApiService.PresetReportService.presetReport_RetrieveCarriers(
    selectedDomainId
  );
};

const useGetCarriers = (selectedDomainId?: string | null | undefined) => {
  return useQuery([APIQueryKey.retrieveCarriers, selectedDomainId], () =>
    retrieveCarriers(selectedDomainId)
  );
};

export default useGetCarriers;
