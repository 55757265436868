import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CustomTextField from 'components/forms/form-fields/CustomTextField';
import MenuItem from '@material-ui/core/MenuItem';
import { TextFieldProps } from '@material-ui/core/TextField';
import SelectItem from 'components/forms/styled-fields/SelectItem';
import { Field, FieldAttributes } from 'formik';
import AirProductsSiteAutocomplete from 'components/forms/form-fields/AirProductsSiteAutocomplete';
import SiteAutocomplete from 'components/forms/form-fields/SiteAutocomplete';
import SwitchWithLabel from 'components/forms/form-fields/SwitchWithLabel';
import StyledTextField from 'components/forms/styled-fields/StyledTextField';
import TimeField from 'components/forms/styled-fields/StyledTimeField';
import { SiteInfoDto } from 'api/admin/api';
import { StyledFieldLabelText, StyledValueText } from './styles';

type Option = {
  label: string;
  value: string | number;
};
type EditorTextProps = {
  label: string | React.ReactNode;
  value?: React.ReactNode | string | null;
};
type EditorTextBoxProps = FieldAttributes<any> & {
  label: string;
};
type EditorSwitchProps = FieldAttributes<any> & {
  label: string;
  switchLabel?: string;
};
type EditorDropDownProps = FieldAttributes<any> & {
  label: string;
  name: string;
  textMapping: Record<number, string>;
  isMandatory?: boolean;
};
type EditorGridLayoutProps = {
  label: string | React.ReactNode;
  error?: string;
  touched?: boolean;
  control: React.ReactNode;
  alignment?: string;
};
type EditorGridLayoutThreeColumnsProps = {
  label: string;
  error?: string;
  touched?: boolean;
  control: React.ReactNode;
  help?: string;
};
type EditorSiteDropDownProps = {
  domainId?: string;
  userId?: string;
  label: string;
  name: string;
  placeholder: string;
  selectedSiteId: SiteInfoDto | null;
  nonAirProductsDomain?: boolean;
  error?: string;
  touched?: boolean;

  setSelectedSiteId: React.Dispatch<React.SetStateAction<SiteInfoDto | null>>;
};
const EditorGridLayout = ({
  label,
  control,
  alignment,
}: EditorGridLayoutProps) => {
  return (
    <>
      {alignment === 'horizontal' ? (
        <>
          <Grid item xs={4}>
            <StyledFieldLabelText>{label}</StyledFieldLabelText>
          </Grid>
          <Grid item xs={8}>
            {control}
          </Grid>
        </>
      ) : (
        <Box>
          <StyledFieldLabelText>{label}</StyledFieldLabelText>
          {control}
        </Box>
      )}
    </>
  );
};

const EditorGridLayoutThreeColumns = ({
  label,
  control,
  help,
}: EditorGridLayoutThreeColumnsProps) => {
  return (
    <>
      <Grid item xs={4}>
        <StyledFieldLabelText>{label}</StyledFieldLabelText>
      </Grid>
      <Grid item xs={4}>
        {control}
      </Grid>{' '}
      <Grid item xs={4}>
        <StyledValueText>{help}</StyledValueText>
      </Grid>
    </>
  );
};

export const EditorText = ({ label, value }: EditorTextProps) => {
  return (
    <EditorGridLayout
      label={label}
      control={<StyledValueText>{value}</StyledValueText>}
    />
  );
};
export const EditorTextBox = ({
  label,
  name,
  initialValue,
  inputProps,
  error,
  type,
  onInput,
  onBlur,
  styleInput,
  placeholder,
  ...props
}: EditorTextBoxProps) => {
  return (
    <EditorGridLayout
      {...props}
      label={label}
      control={
        <>
          <Field
            style={styleInput}
            type={type}
            initialInputValue={initialValue}
            initialValue={initialValue}
            id={`${name}-textbox`}
            inputProps={inputProps}
            name={name}
            error={error}
            onInput={onInput}
            onBlur={onBlur}
            component={CustomTextField}
            placeholder={placeholder}
          />
          <Typography variant="caption" color="error">
            {props?.errorMessage}
          </Typography>
        </>
      }
    />
  );
};

export const EditorTextBoxHelp = ({
  label,
  name,
  help,
  ...props
}: EditorTextBoxProps) => {
  return (
    <EditorGridLayoutThreeColumns
      label={label}
      help={help}
      control={
        <Field
          id={`${name}-textbox`}
          name={name}
          component={CustomTextField}
          {...props}
        />
      }
    />
  );
};

export const EditorNumberBox = ({
  label,
  name,
  max,
  min = 0,
  onInput,
  ...props
}: EditorTextBoxProps) => {
  return (
    <EditorGridLayout
      {...props}
      label={label}
      control={
        <Field
          id={`${name}-textbox`}
          name={name}
          type="number"
          onInput={onInput}
          max={max}
          min={min}
          component={CustomTextField}
        />
      }
    />
  );
};

export const FieldTextBox = ({ name, ...props }: FieldAttributes<any>) => {
  return (
    <Field
      {...props}
      id={`${name}-textbox`}
      name={name}
      component={CustomTextField}
    />
  );
};

export const FieldDopdownBox = ({
  label,
  name,
  textMapping,
  error,
  touched,
  isMandatory,
  typeList,
  ...props
}: EditorDropDownProps) => {
  const mappedList = Object.keys(textMapping).map((key) => ({
    key,
    value: textMapping[key as any],
  }));

  const mappedListWithOutNone = mappedList.filter(
    (ele) => ele.value !== 'None'
  );
  const noneValue = mappedList.filter((ele) => ele.value === 'None');

  let sortedList = [];
  if (typeList && typeList === 'numeric') {
    sortedList = mappedListWithOutNone.sort((a, b) => a.value - b.value);
  } else {
    sortedList = mappedListWithOutNone.sort((a, b) =>
      a.value > b.value ? 1 : -1
    );
  }

  return (
    <Field
      {...props}
      id={`${name}-dropdown`}
      name={name}
      component={CustomTextField}
      select
      SelectProps={{ displayEmpty: true }}
    >
      {!isMandatory && (
        <MenuItem value="">
          <SelectItem />
        </MenuItem>
      )}
      {[...noneValue, ...sortedList].map((item) => (
        <MenuItem key={item.key} value={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </Field>
  );
};

export const EditorDropDown = ({
  label,
  name,
  textMapping,
  error,
  touched,
  isMandatory,
  typeList,
  alignment,
  nonSort,
  sortedArray,
  value,
  selectprops,
  ...props
}: EditorDropDownProps) => {
  const mappedList = Object.keys(textMapping).map((key) => ({
    key,
    value: textMapping[key as any],
  }));

  const mappedListWithOutNone = mappedList.filter(
    (ele) => ele.value !== 'None'
  );
  const noneValue = mappedList.filter((ele) => ele.value === 'None');

  let sortedList = [];
  if (nonSort) {
    sortedList = sortedArray || mappedList;
  }

  if (!nonSort && typeList && typeList === 'numeric') {
    sortedList = mappedListWithOutNone.sort((a, b) => a.value - b.value);
  } else if (!nonSort) {
    sortedList = mappedListWithOutNone.sort((a, b) =>
      a.value > b.value ? 1 : -1
    );
  }

  // const sortedList = mappedList.sort((a, b) => (a.value > b.value ? 1 : -1));

  return (
    <EditorGridLayout
      label={label}
      alignment={alignment}
      control={
        <>
          <Field
            {...props}
            error={error}
            id={`${name}-dropdown`}
            name={name}
            component={CustomTextField}
            select
            SelectProps={{ displayEmpty: true, ...selectprops }}
          >
            {!isMandatory && (
              <MenuItem value="">
                <SelectItem />
              </MenuItem>
            )}
            {[...noneValue, ...sortedList].map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
            {/* {sortedList.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.value}
            </MenuItem>
          ))} */}
          </Field>
          <Typography variant="caption" color="error">
            {props?.errorMessage}
          </Typography>
        </>
      }
      error={error}
      touched={touched}
    />
  );
};
export const EditorSiteDropDown = ({
  userId,
  domainId,
  label,
  name,
  placeholder,
  selectedSiteId,
  nonAirProductsDomain,
  setSelectedSiteId,
  touched,
  error,
}: EditorSiteDropDownProps) => {
  return (
    <EditorGridLayout
      label={label}
      control={
        nonAirProductsDomain ? (
          <Field
            id={`${name}-input`}
            name={name}
            domainId={domainId}
            userId={userId}
            component={SiteAutocomplete}
            initialInputValue={selectedSiteId?.id}
            initialValue={selectedSiteId}
            selectedOption={selectedSiteId}
            onChange={setSelectedSiteId}
            textFieldProps={{
              placeholder,
            }}
            touched={touched}
            error={error}
          />
        ) : (
          <Field
            id={`${name}-input`}
            name={name}
            component={AirProductsSiteAutocomplete}
            selectedOption={selectedSiteId}
            onChange={setSelectedSiteId}
            textFieldProps={{
              placeholder,
            }}
            storeSiteId
            touched={touched}
            error={error}
          />
        )
      }
    />
  );
};

export const Select = ({
  options,
  ...textFieldProps
}: {
  options: Option[];
} & TextFieldProps) => {
  return (
    <StyledTextField
      {...textFieldProps}
      select
      SelectProps={{ displayEmpty: true }}
      style={{ textTransform: 'none' }}
    >
      {options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </StyledTextField>
  );
};

export const EditorSwitch = ({
  label,
  name,
  switchLabel,
  onLabel,
  offLabel,
  onChange,
  value,
  ...props
}: EditorSwitchProps) => {
  return (
    <EditorGridLayout
      {...props}
      label={label}
      control={
        <Field
          id={`${name}-switch`}
          name={name}
          onInput={onChange}
          component={SwitchWithLabel}
          type="checkbox"
          Label={{
            label: switchLabel,
          }}
          value={value}
          onLabel={onLabel}
          offLabel={offLabel}
        />
      }
    />
  );
};

export const EditorTime = ({
  label,
  name,
  error,
  ...props
}: EditorSwitchProps) => {
  return (
    <EditorGridLayout
      {...props}
      label={label}
      control={
        <>
          <Field
            id={`${name}-textbox`}
            name={name}
            component={TimeField}
            error={error}
            {...props}
          />
          <Typography variant="caption" color="error">
            {props?.errorMessage}
          </Typography>
        </>
      }
    />
  );
};
export type EditorFieldGenericProps = (
  | EditorTextProps
  | EditorTextBoxProps
  | EditorDropDownProps
  | EditorSwitchProps
) & {
  type:
    | 'switch'
    | 'dropdown'
    | 'text'
    | 'textbox'
    | 'boolean'
    | 'textboxnumber'
    | 'timefield';
};

export const EditorFieldGeneric = ({
  type,
  textMapping,
  indeterminate,
  ...props
}: EditorFieldGenericProps) => {
  if (type === 'text' || type === 'boolean' || type === undefined) {
    return <EditorText {...props} />;
  }
  if (type === 'textbox') {
    return (
      <>
        <Field
          id={`${props?.name}-textbox`}
          name={props?.name}
          component={CustomTextField}
          {...props}
          inputProps={{
            maxLength: props?.maxLength,
            minLength: props?.minLength,
          }}
        />
        <Typography variant="caption" color="error">
          {props?.errorMessage}
        </Typography>
      </>
    );
  }
  if (type === 'textboxnumber') {
    return (
      <>
        <Field
          id={`${props?.name}-textboxnumber`}
          name={props?.name}
          component={CustomTextField}
          inputProps={{ maxLength: props?.maxLength }}
          onInput={(
            e: React.ChangeEvent<{
              value: string;
              maxLength: number;
            }>
          ) => {
            e.target.value = e.target.value.replace(/[^0-9.-]/, '');
            if (e.target.value.length > e.target.maxLength)
              e.target.value = e.target.value.slice(0, e.target.maxLength);
            if (props?.max && Number(e.target.value) > props?.max)
              e.target.value = `${props?.max}`;
            if (
              (props?.min || props?.min === 0) &&
              props?.forceMin &&
              props?.onlyPositive &&
              (Number(e.target.value) < props?.min || e.target.value === '')
            )
              e.target.value = `${props?.min}`;
            if (
              (props?.min || props?.min === 0) &&
              !props?.forceMin &&
              props?.onlyPositive &&
              Number(e.target.value) < props?.min
            )
              e.target.value = '0';
          }}
          {...props}
        />
        <Typography variant="caption" color="error">
          {props?.errorMessage}
        </Typography>
      </>
    );
  }
  if (type === 'switch') {
    return (
      <EditorSwitch
        {...props}
        onLabel={props?.boolText?.enabled}
        offLabel={props?.boolText?.disabled}
      />
    );
  }
  if (type === 'dropdown') {
    return (
      <>
        <FieldDopdownBox {...props} textMapping={textMapping} />
        <Typography variant="caption" color="error">
          {props?.errorMessage}
        </Typography>
      </>
    );
  }

  if (type === 'timefield') {
    return (
      <>
        <Field
          id={`${props?.name}-textbox`}
          name={props?.name}
          component={TimeField}
          {...props}
        />
      </>
    );
  }
  return null;
};
