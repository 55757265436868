/* eslint-disable indent */
/* eslint-disable no-console */
import { ADMIN_BASE_SIGNALR_API_URL } from 'env';
import { ApiService } from 'api/admin/ApiService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { UserPermissionType } from 'api/admin/api';
import { ReactComponent as BellIcon } from 'assets/icons/icon-bell-notification.svg';
import uniq from 'lodash/uniq';
import Grid from '@material-ui/core/Grid';
import adminRoutes from 'apps/admin/routes';
import opsRoutes from 'apps/ops/routes';
import reportsRoutes from 'apps/reports/routes';
import freezersRoutes from 'apps/freezers/routes';
import systemRoutes from 'apps/system/routes';
import clsx from 'clsx';
import AppSwitcher from 'components/AppSwitcher';
import {
  LeftNavContent,
  StyledAppBar,
  StyledDivider,
  StyledToolbar,
  useTopNavigationStyles,
  StyledNavbarCaretButton,
} from 'components/navigation/common';
import DomainSwitcher from 'components/navigation/DomainSwitcher';
import LogoAndAppName from 'components/navigation/LogoAndAppName';
import TimezoneSwitcher from 'components/navigation/TimezoneSwitcher';
import UserAvatarMenu from 'components/navigation/UserAvatarMenu';

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  selectEvents,
  selectActiveDomain,
  selectShowOpsNavBar,
  selectActiveDomainId,
} from 'redux-app/modules/app/selectors';
import {
  selectIsUserSystemAdmin,
  selectHasPermission,
  selectUserId,
} from 'redux-app/modules/user/selectors';
import { setEvents } from 'redux-app/modules/app/actions';
import DomainNoteLink from 'components/navigation/DomainNoteLink';
import ThemedTooltip from 'components/ThemedTooltip';
import AboutMenu from '../../../../../components/AboutMenu';
import BreadcrumbBar from '../BreadcrumbBar';
import { Badge } from './styles';

const TopNavigation = ({
  appName,
  menuOption,
}: {
  appName: string;
  menuOption: string;
}) => {
  const { t } = useTranslation();
  const userId = useSelector(selectUserId);
  const activeDomainId = useSelector(selectActiveDomainId);
  const activeDomain = useSelector(selectActiveDomain);
  const [keySignalR, setKeySignalR] = useState<string | null>();

  const fetchCookie = useCallback(() => {
    return ApiService.UserService.user_RetrieveSignalRFunctionAppApiKey()
      .then((response) => {
        setKeySignalR(response);
      })
      .catch((error) => {
        console.error('Error while get keySignalR', error);
      });
  }, [activeDomainId]);

  useEffect(() => {
    fetchCookie();
  }, [activeDomainId]);

  const connection = useMemo(() => {
    if (ADMIN_BASE_SIGNALR_API_URL && keySignalR && userId) {
      const urlSignalR = `${ADMIN_BASE_SIGNALR_API_URL}/${userId}/${keySignalR}`;
      console.info('urlSignalR', urlSignalR);
      return new HubConnectionBuilder().withUrl(urlSignalR).build();
    }
    return null;
  }, [keySignalR, setKeySignalR, userId, activeDomainId]);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useTopNavigationStyles();
  const showOpsNav = useSelector(selectShowOpsNavBar);

  const currentEvents = useSelector(selectEvents);
  const isSystemAdmin = useSelector(selectIsUserSystemAdmin);

  const hasPermission = useSelector(selectHasPermission);

  const canViewEventsTab = hasPermission(UserPermissionType.ViewTabEvents);

  const [bellCounter, setBellCounter] = useState<any>(currentEvents || {});

  const latestEvent: { current: any | undefined } = useRef();

  latestEvent.current = bellCounter;

  const handleMessage = (message: any, domainId: string) => {
    const latestData = latestEvent.current || {};
    const updatedEvents = { ...latestData };

    const messageArray = JSON.parse(message);

    if (updatedEvents[domainId]) {
      updatedEvents[domainId] = [...updatedEvents[domainId], ...messageArray];
    } else {
      updatedEvents[domainId] = [...messageArray];
    }

    setBellCounter(updatedEvents);
    dispatch(setEvents(updatedEvents));
  };

  useEffect(() => {
    if (connection && activeDomain && activeDomain.domainId) {
      // @ts-ignore
      connection.stop().then(() => {
        // @ts-ignore
        connection
          // @ts-ignore
          .start()
          .then(() => {
            // @ts-ignore
            connection.on(activeDomain.domainId as string, (message) =>
              handleMessage(message, activeDomain.domainId as string)
            );
          })
          .catch((e: any) => console.log('Connection failed: ', e));
      });
    }
  }, [activeDomain?.domainId, connection]);

  useEffect(() => {
    setBellCounter(currentEvents);
  }, [currentEvents]);

  const handleSelectEvents = () => {
    setBellCounter({});
    dispatch(setEvents({}));
    return history.push(opsRoutes.events.list);
  };

  const handleSelectDomain = () => {
    setBellCounter({});
    dispatch(setEvents({}));
    const currentPathName = location.pathname;
    const isUserInAssetNavigatorPath = matchPath(currentPathName, {
      path: opsRoutes.assetNavigator.list,
    });
    const isUserInAssetSummaryPath = matchPath(currentPathName, {
      path: opsRoutes.assetSummary.list,
    });
    const isUserInEventsPath = matchPath(currentPathName, {
      path: opsRoutes.events.list,
    });
    const isUserInAssetMapPath = matchPath(currentPathName, {
      path: opsRoutes.assetMap.list,
    });
    if (isUserInAssetNavigatorPath) {
      return history.push(opsRoutes.assetNavigator.list);
    }
    if (isUserInAssetSummaryPath) {
      return history.push(opsRoutes.assetSummary.list);
    }
    if (isUserInEventsPath) {
      return history.push(opsRoutes.events.list);
    }
    if (isUserInAssetMapPath) {
      return history.push(opsRoutes.assetMap.list);
    }

    // #region Asset pages
    const isUserInAssetConfigPath = matchPath(currentPathName, {
      path: adminRoutes.assetManager.list,
    });
    const isUserInDataChannelPath = matchPath(currentPathName, {
      path: adminRoutes.dataChannelManager.list,
    });
    const isUserInRtuPath = matchPath(currentPathName, {
      path: adminRoutes.rtuManager.list,
    });
    const isUserInSitePath = matchPath(currentPathName, {
      path: adminRoutes.siteManager.list,
    });
    const isUserInTankDimensionPath = matchPath(currentPathName, {
      path: adminRoutes.tankDimensionManager.list,
    });
    const isUserInProductPath = matchPath(currentPathName, {
      path: adminRoutes.productManager.list,
    });
    const isUserInPollSchedulePath = matchPath(currentPathName, {
      path: adminRoutes.pollScheduleManager.list,
    });
    const isUserInAssetGroupPath = matchPath(currentPathName, {
      path: adminRoutes.assetGroupManager.list,
    });
    const isUserInAssetTreePath = matchPath(currentPathName, {
      path: adminRoutes.assetTreeManager.list,
    });
    // #endregion Asset pages

    // #region Domain pages
    const isUserInDomainPath = matchPath(currentPathName, {
      path: adminRoutes.domainManager.list,
    });
    // #endregion Domain pages

    // #region Events pages
    const isUserInMessageTemplatePath = matchPath(currentPathName, {
      path: adminRoutes.messageTemplateManager.list,
    });
    const isUserInRosterPath = matchPath(currentPathName, {
      path: adminRoutes.rosterManager.list,
    });
    const isUserInGeofencePath = matchPath(currentPathName, {
      path: adminRoutes.geofenceManager.list,
    });
    // #endregion Events pages

    // #region User pages
    const isUserInUserAdminPath = matchPath(currentPathName, {
      path: adminRoutes.userManager.list,
    });
    // #endregion User pages

    // Asset pages
    if (isUserInAssetConfigPath) {
      return history.push(adminRoutes.assetManager.list);
    }
    if (isUserInDataChannelPath) {
      return history.push(adminRoutes.dataChannelManager.list);
    }
    if (isUserInRtuPath) {
      return history.push(adminRoutes.rtuManager.list);
    }
    if (isUserInSitePath) {
      return history.push(adminRoutes.siteManager.list);
    }
    if (isUserInTankDimensionPath) {
      return history.push(adminRoutes.tankDimensionManager.list);
    }
    if (isUserInProductPath) {
      return history.push(adminRoutes.productManager.list);
    }
    if (isUserInPollSchedulePath) {
      return history.push(adminRoutes.pollScheduleManager.list);
    }
    if (isUserInAssetGroupPath) {
      return history.push(adminRoutes.assetGroupManager.list);
    }
    if (isUserInAssetTreePath) {
      return history.push(adminRoutes.assetTreeManager.list);
    }

    // Domain pages
    if (isUserInDomainPath) {
      return history.push(adminRoutes.domainManager.list);
    }

    // Events pages
    if (isUserInMessageTemplatePath) {
      return history.push(adminRoutes.messageTemplateManager.list);
    }
    if (isUserInRosterPath) {
      return history.push(adminRoutes.rosterManager.list);
    }
    if (isUserInGeofencePath) {
      return history.push(adminRoutes.geofenceManager.list);
    }

    // User pages
    if (isUserInUserAdminPath) {
      return history.push(adminRoutes.userManager.list);
    }

    const isUserInSitesPath = matchPath(currentPathName, {
      path: freezersRoutes.sites.list,
    });
    const isUserInDefaultChartManagerPath = matchPath(currentPathName, {
      path: freezersRoutes.defaultChartManager.list,
    });

    if (isUserInSitesPath) {
      return history.push(freezersRoutes.sites.list);
    }
    if (isUserInDefaultChartManagerPath) {
      return history.push(freezersRoutes.defaultChartManager.list);
    }

    const isUserInSearchPath = matchPath(currentPathName, {
      path: systemRoutes.search,
    });

    if (isUserInSearchPath) {
      return history.push(systemRoutes.search);
    }

    if (menuOption === 'Admin') {
      return history.push(adminRoutes.assetManager.list);
    }

    if (menuOption === 'Ops') {
      return history.push(opsRoutes.base);
    }

    if (menuOption === 'Reports') {
      return history.push(reportsRoutes.base);
    }

    if (menuOption === 'Freezers') {
      return history.push(freezersRoutes.base);
    }

    if (menuOption === 'System') {
      return history.push(systemRoutes.base);
    }

    return history.push(opsRoutes.base);
  };

  const linkToDomainNote = activeDomain?.domainId
    ? generatePath(adminRoutes.domainManager.notes, {
        domainId: activeDomain ? activeDomain?.domainId : '',
      })
    : '';

  const areThereDomainNotes = !!activeDomain?.domainAdditionalInfo
    ?.areThereDomainNotes;

  const baseRoute = (): string => {
    switch (menuOption) {
      case 'Admin':
        return adminRoutes.base;
      case 'Ops':
        return opsRoutes.base;
      case 'Reports':
        return reportsRoutes.base;
      case 'Freezers':
        return freezersRoutes.base;
      case 'System':
        return systemRoutes.base;
      default:
        return adminRoutes.base;
    }
  };

  const userAvatarMenuLanguage = (): string => {
    switch (menuOption) {
      case 'Admin':
        return adminRoutes.language;
      case 'Ops':
        return opsRoutes.language;
      case 'Reports':
        return reportsRoutes.language;
      case 'Freezers':
        return freezersRoutes.language;
      case 'System':
        return systemRoutes.language;
      default:
        return adminRoutes.language;
    }
  };

  const userAvatarMenuProfile = (): string => {
    switch (menuOption) {
      case 'Admin':
        return adminRoutes.userProfile;
      case 'Ops':
        return opsRoutes.userProfile;
      case 'Reports':
        return reportsRoutes.userProfile;
      case 'Freezers':
        return freezersRoutes.userProfile;
      case 'System':
        return systemRoutes.userProfile;
      default:
        return adminRoutes.userProfile;
    }
  };

  const aboutMenuNotes = (): string => {
    switch (menuOption) {
      case 'Admin':
        return adminRoutes.releaseNotes;
      case 'Ops':
        return opsRoutes.releaseNotes;
      case 'Reports':
        return reportsRoutes.releaseNotes;
      case 'Freezers':
        return freezersRoutes.releaseNotes;
      case 'System':
        return systemRoutes.releaseNotes;
      default:
        return adminRoutes.releaseNotes;
    }
  };

  const aboutMenuSupport = (): string => {
    switch (menuOption) {
      case 'Admin':
        return adminRoutes.contactSupport;
      case 'Ops':
        return opsRoutes.contactSupport;
      case 'Reports':
        return reportsRoutes.contactSupport;
      case 'Freezers':
        return freezersRoutes.contactSupport;
      case 'System':
        return systemRoutes.contactSupport;
      default:
        return adminRoutes.contactSupport;
    }
  };

  const aboutMenuDolv3 = (): string => {
    switch (menuOption) {
      case 'Admin':
        return adminRoutes.accessDolv3;
      case 'Ops':
        return opsRoutes.accessDolv3;
      case 'Reports':
        return reportsRoutes.accessDolv3;
      case 'Freezers':
        return freezersRoutes.accessDolv3;
      case 'System':
        return systemRoutes.accessDolv3;
      default:
        return adminRoutes.accessDolv3;
    }
  };

  return (
    <div>
      <StyledAppBar
        position="fixed"
        color="default"
        elevation={0}
        className={clsx(classes.appBar)}
      >
        <StyledToolbar>
          <AppSwitcher />

          <LeftNavContent>
            <LogoAndAppName appName={appName} logoLinkTo={baseRoute()} />
          </LeftNavContent>

          {/* Search bar temporarily removed [sept 23, 2020] */}
          {/* <OpsSearchBar /> */}

          <div>
            <Grid container spacing={0} alignItems="center">
              <>
                {activeDomain && isSystemAdmin && areThereDomainNotes && (
                  <Grid item>
                    <DomainNoteLink url={linkToDomainNote} />
                  </Grid>
                )}

                <Grid item>
                  <StyledDivider orientation="vertical" flexItem />
                </Grid>

                <Grid item>
                  <DomainSwitcher
                    onSelectDomain={handleSelectDomain}
                    connection={connection}
                  />
                </Grid>
                <Grid item>
                  <StyledDivider orientation="vertical" flexItem />
                </Grid>
                <Grid item>
                  <TimezoneSwitcher condensed="md" />
                </Grid>
                <Grid item>
                  <StyledDivider orientation="vertical" flexItem />
                </Grid>
                {canViewEventsTab && (
                  <Grid item>
                    {(activeDomain?.domainId &&
                      bellCounter &&
                      uniq(bellCounter[activeDomain?.domainId]).length === 0) ||
                    !bellCounter ? (
                      <StyledNavbarCaretButton
                        variant="text"
                        style={{ minWidth: 36, marginLeft: 8 }}
                        onClick={handleSelectEvents}
                      >
                        <ThemedTooltip
                          arrow
                          title={`${t(
                            'ui.common.nonewevents',
                            'No new Events'
                          )}`}
                          placement="bottom"
                        >
                          <BellIcon />
                        </ThemedTooltip>
                      </StyledNavbarCaretButton>
                    ) : (
                      <StyledNavbarCaretButton
                        variant="text"
                        style={{ minWidth: 36, marginLeft: 8 }}
                        onClick={handleSelectEvents}
                      >
                        <BellIcon />
                        <ThemedTooltip
                          arrow
                          title={`${
                            uniq(bellCounter[activeDomain?.domainId as string])
                              .length > 99
                              ? '99+'
                              : uniq(
                                  bellCounter[activeDomain?.domainId as string]
                                ).length
                          } ${t('ui.common.newevents', 'New Events')}`}
                          placement="bottom"
                        >
                          <Badge>
                            {activeDomain?.domainId &&
                              bellCounter &&
                              (uniq(bellCounter[activeDomain?.domainId])
                                .length > 99
                                ? '99+'
                                : uniq(bellCounter[activeDomain?.domainId])
                                    .length)}
                          </Badge>
                        </ThemedTooltip>
                      </StyledNavbarCaretButton>
                    )}
                  </Grid>
                )}
                <Grid item>
                  <AboutMenu
                    releaseNotesRoute={aboutMenuNotes()}
                    contactSupportRoute={aboutMenuSupport()}
                    accessDolv3Route={aboutMenuDolv3()}
                  />
                </Grid>
              </>

              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <UserAvatarMenu
                      languageRoute={userAvatarMenuLanguage()}
                      userProfileRoute={userAvatarMenuProfile()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </StyledToolbar>
        {showOpsNav && <BreadcrumbBar />}
      </StyledAppBar>
    </div>
  );
};

export default TopNavigation;
