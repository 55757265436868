import { PresetReportDto } from 'api/admin/api';
import { ApiService } from 'api/admin/ApiService';
import { useMutation } from 'react-query';

const useSaveReportInfo = () => {
  const mutuation = useMutation((formData: PresetReportDto) => {
    return ApiService.PresetReportService.presetReport_UpdatePresetReport(
      formData
    );
  });
  return mutuation;
};
export default useSaveReportInfo;
