import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { PresetReportDto } from 'api/admin/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FieldGroup from 'components/forms/styled-fields/FieldGroup';
import { FilterOptions } from '../types';

import { EditorDropDown, EditorTextBox } from '../common/EditorFields';

export const StyledValueText = styled(Typography)`
  font-size: ${(props) => props.theme.custom.fontSize?.tableCells};
`;

const FormUserList = ({
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
  values,
  setValues,
}: {
  data?: PresetReportDto;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
  values?: PresetReportDto;
  setValues: any;
}) => {
  const { t } = useTranslation();

  const selectFilterByOptions = () => ({
    [FilterOptions.Company]: t('ui.common.company', 'Company'),
    [FilterOptions.FirstName]: t('ui.common.firstname', 'First Name'),
    [FilterOptions.LastName]: t('ui.common.lastname', 'Last Name'),
    [FilterOptions.Role]: t('ui.common.role', 'Role'),
  });

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.timezone', 'Time Zone')}*`}
            name="timeZoneId"
            nonSort
            sortedArray={sortedTimeZoneArray}
            textMapping={optionsTimeZone}
            isMandatory
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EditorDropDown
            label={`${t('ui.reports.language', 'Language')}`}
            name="displayLanguageEnumValue"
            textMapping={optionsLanguage}
            isMandatory
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} alignItems="center">
        <Grid item sm={3} xs={12}>
          <EditorTextBox
            label={t('ui.reports.filterbyusername', 'Filter by User Name')}
            name="filterText"
            onChange={() => setValues('filterByEnumValue', 'UserName')}
            placeholder={t(
              'ui.common.filterplaceholder',
              `Enter {{filterOption}}`,
              {
                filterOption: selectFilterByOptions()[
                  // @ts-ignore
                  values.filterByEnumValue
                ],
              }
            )}
          />
        </Grid>
        <Grid
          item
          sm={1}
          xs={12}
          style={{ marginTop: '16px', textAlign: 'center' }}
        >
          <StyledValueText>{t('ui.common.and', 'And')}</StyledValueText>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FieldGroup style={{ display: 'flex' }}>
            <EditorDropDown
              label={`${t(
                'ui.reports.additionalfilterby',
                'Additional Filter'
              )}`}
              name="otherFilterByEnumValue"
              textMapping={selectFilterByOptions()}
              style={{ minWidth: '200px' }}
              isMandatory
            />

            <EditorTextBox
              name="otherFilterText"
              styleInput={{ minWidth: '300px', marginTop: '18px' }}
              placeholder={t(
                'ui.common.filterplaceholder',
                `Enter {{filterOption}}`,
                {
                  filterOption: selectFilterByOptions()[
                    // @ts-ignore
                    values.otherFilterByEnumValue
                  ],
                }
              )}
            />
          </FieldGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default FormUserList;
