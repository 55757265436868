import { useSelector } from 'react-redux';
import { UserPermissionType } from 'api/admin/api';
import { AccessType } from 'types';
import routes from 'apps/reports/routes';
import AuthedRoute from 'components/AuthedRoute';
import CommonRoutes from 'components/CommonRoutes';
import PermissionGatedRoute from 'components/routes/PermissionGatedRoute';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { selectHasPermission } from 'redux-app/modules/user/selectors';
import QuickTankCreate from './containers/QuickAssetCreateReport';
import ReportsList from './containers/ReportsList';
import ReportsEditor from './containers/ReportsEditor';
import CreateReport from './containers/CreateReport';
import NewReportsList from './containers/NewReportsList';

function ReportsApp() {
  const hasPermission = useSelector(selectHasPermission);

  const canAccessReport = hasPermission(
    UserPermissionType.ReportAccess,
    AccessType.Read
  );
  return (
    <>
      <Switch>
        <AuthedRoute path={routes.base} exact>
          <Redirect to={routes.reports.list} />
        </AuthedRoute>
        <AuthedRoute path={routes.reports.list} exact>
          <ReportsList />
        </AuthedRoute>
        <AuthedRoute path={routes.reports.create} exact>
          <CreateReport />
        </AuthedRoute>

        <PermissionGatedRoute
          path={routes.reports.edit}
          exact
          hasPermission={canAccessReport}
          render={(props) => (
            <ReportsEditor reportId={props.match.params.reportId} />
          )}
        />
        <AuthedRoute path={routes.newReport.home} exact>
          <NewReportsList />
        </AuthedRoute>
        <AuthedRoute path={routes.newReport.quickAssetCreate} exact>
          <QuickTankCreate />
        </AuthedRoute>
        <CommonRoutes routes={routes} />
      </Switch>
    </>
  );
}

export default ReportsApp;
