import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormErrorAlert from 'components/FormErrorAlert';
import { FormikProps } from 'formik';
import BackIconButton from 'components/buttons/BackIconButton';
import CancelButton from 'components/buttons/CancelButton';
import SaveAndExitButton from 'components/buttons/SaveAndExitButton';
import PageHeader from 'components/PageHeader';
import DefaultTransition from 'components/common/animations/DefaultTransition';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

enum SaveType {
  Save = 'save',
  SaveAndExit = 'save-and-exit',
}

interface Props<T = any> {
  isCreating?: boolean;
  isSubmitting?: boolean;
  submissionResult?: T;
  submissionError?: T;
  submitForm?: FormikProps<any>['submitForm'];
  cancelCallback?: () => void;
  saveCallback?: (response: T) => void;
  saveAndExitCallback?: (response: T) => void;
  isReadOnly?: boolean;
}

const PageIntro = ({
  isCreating,
  isSubmitting,
  submissionResult,
  submissionError,
  submitForm,
  cancelCallback,
  saveCallback,
  saveAndExitCallback,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isBelowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const [saveType, setSaveType] = useState<SaveType>();

  const cancel = () => {
    cancelCallback?.();
  };

  const submitAndGoToListPage = () => {
    setSaveType(SaveType.SaveAndExit);
    submitForm?.().then(() => {
      setSaveType(SaveType.SaveAndExit);
    });
  };

  useEffect(() => {
    if (!submissionResult || isSubmitting) {
      return;
    }
    if (saveType === SaveType.Save) {
      saveCallback?.(submissionResult);
    } else if (saveType === SaveType.SaveAndExit) {
      saveAndExitCallback?.(submissionResult);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [submissionResult, saveType, isSubmitting]);

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item xs={12} md="auto">
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <BackIconButton onClick={() => history.goBack()} />
            </Grid>
            <Grid item>
              <PageHeader dense>
                {isCreating
                  ? t('ui.reports.addreport', 'Add Report')
                  : t('ui.reports.editreport', 'Edit Report')}
              </PageHeader>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          {!isReadOnly && (
            <Box
              clone
              justifyContent={['space-between', 'space-between', 'flex-end']}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item {...(isBelowSmBreakpoint && { xs: true })}>
                  <CancelButton
                    onClick={cancel}
                    fullWidth={isBelowSmBreakpoint}
                    useDomainColorForIcon
                  />
                </Grid>

                <Grid item {...(isBelowSmBreakpoint && { xs: true })}>
                  <SaveAndExitButton
                    onClick={submitAndGoToListPage}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <DefaultTransition in={!!submissionError} unmountOnExit>
          <Grid item xs={12}>
            <FormErrorAlert>
              {submissionError && submissionError[0]?.errorMessage
                ? submissionError[0]?.errorMessage
                : t('ui.common.unableToSave', 'Unable to save')}
            </FormErrorAlert>
          </Grid>
        </DefaultTransition>
      </Grid>
    </Box>
  );
};

export default PageIntro;
