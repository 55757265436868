import Grid from '@material-ui/core/Grid';
import { PresetReportDto } from 'api/admin/api';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditorDropDown } from '../common/EditorFields';

const FormUserRoleListEventRule = ({
  optionsTimeZone,
  sortedTimeZoneArray,
  optionsLanguage,
}: {
  data?: PresetReportDto;
  optionsTimeZone: { [key: string]: string };
  sortedTimeZoneArray?: {
    key: string | number;
    value: string | number;
  }[];
  optionsLanguage: { [key: string]: string };
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item sm={4} xs={12}>
        <EditorDropDown
          label={`${t('ui.reports.timezone', 'Time Zone')}*`}
          name="timeZoneId"
          isMandatory
          nonSort
          sortedArray={sortedTimeZoneArray}
          textMapping={optionsTimeZone}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <EditorDropDown
          label={`${t('ui.reports.language', 'Language')}`}
          name="displayLanguageEnumValue"
          textMapping={optionsLanguage}
          isMandatory
        />
      </Grid>
    </Grid>
  );
};

export default FormUserRoleListEventRule;
