import { PresetReportCreateDto } from 'api/admin/api';
import { ApiService } from 'api/admin/ApiService';
import { useMutation } from 'react-query';

const useCreateReport = () => {
  const mutuation = useMutation((formData: PresetReportCreateDto) => {
    return ApiService.PresetReportService.presetReport_CreatePresetReport(
      formData
    );
  });
  return mutuation;
};
export default useCreateReport;
