import { PresetReportScheduleDto } from 'api/admin/api';
import { ApiService } from 'api/admin/ApiService';
import { useMutation } from 'react-query';

const useAddSchedule = (presetReportId: number) => {
  const mutuation = useMutation((formData: PresetReportScheduleDto) => {
    return ApiService.PresetReportService.presetReport_CreatePresetReportSchedule(
      presetReportId,
      formData
    );
  });
  return mutuation;
};
export default useAddSchedule;
