import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';

const retrieveReportTemplates = () => {
  return ApiService.PresetReportService.presetReport_RetrievePresetReportTemplates();
};

const useGetPresetReportTemplates = () => {
  return useQuery([APIQueryKey.retrieveReportTemplates], () =>
    retrieveReportTemplates()
  );
};

export default useGetPresetReportTemplates;
