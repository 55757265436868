/* eslint-disable indent */

import styled from 'styled-components';

export const Badge = styled.div`
  width: 21px;
  height: 21px;
  background: #fc5d3a;
  font-size: 13px;
  color: #d2d2d2;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-left: 8px;
`;
