import { ReportType } from 'api/admin/api';

export const isReportBuilt = ({
  quickReportType,
}: {
  quickReportType: ReportType;
}) =>
  quickReportType === ReportType.DataChannelsWithMissingReadings ||
  quickReportType === ReportType.LowBatteryVoltage ||
  quickReportType === ReportType.NewAssetList ||
  quickReportType === ReportType.AssetList ||
  quickReportType === ReportType.HornerDOEReport ||
  quickReportType === ReportType.HornerDetailReport ||
  quickReportType > 1000;
