import FileSaver from 'file-saver';
import { ExcelData } from './excel';
import { ReportInfo } from './types';

export const CsvExport = (
  columns: { header: string; key: string; func: any }[],
  data: ExcelData[],
  reportInfo?: ReportInfo
) => {
  const headerData = columns.reduce(
    (acc: string, item: { header: string; key: string }) => {
      return `${acc}"${item.header}",`;
    },
    ''
  );

  let finalResult = '';

  const rows: string[] = [];

  let rowCownter = 0;

  if (reportInfo?.includeColumnHeaders) {
    rows[rowCownter] = headerData.slice(0, -1);
    rowCownter += 1;
  }

  data.forEach((item: ExcelData) => {
    let rowData = '';

    columns.forEach((col) => {
      if (col?.func) {
        rowData = col.func(item.data[0])
          ? `${rowData}"${col.func(item.data[0])}",`
          : `${rowData}" ",`;
      } else {
        rowData =
          item.data[0][col?.key] !== undefined &&
          item.data[0][col?.key] !== null
            ? `${rowData}"${item.data[0][col?.key]}",`
            : `${rowData}" ",`;
      }
    });

    rows[rowCownter] = rowData.slice(0, -1);

    rowCownter += 1;
  });
  finalResult = rows.join('\r\n');

  const blb = new Blob([finalResult], { type: 'text/csv' });

  FileSaver.saveAs(blb, 'default.csv');
};

export const CsvReportForSP = (reportData: any, reportInfo?: ReportInfo) => {
  const rows: string[] = [];
  let rowsCounter = 0;
  const headers: string[] = reportData?.reportResult?.columns.map(
    (col: any) => {
      return col?.name;
    }
  );

  if (reportInfo?.includeColumnHeaders) {
    rows[rowsCounter] = headers?.join(',');
    rowsCounter += 1;
  }

  reportData?.reportResult?.records?.forEach((rec: any) => {
    rows[rowsCounter] = rec?.columnValues
      ?.map((val: any) =>
        val !== null && val !== undefined ? `"${val}"` : `" "`
      )
      .join(',');
    rowsCounter += 1;
  });
  const blb = new Blob([rows?.join('\r\n')], {
    type: 'text/csv',
  });

  FileSaver.saveAs(blb, 'default.csv');
};
