/* eslint-disable indent */
import {
  styledPrimaryListItemCss,
  styledSecondaryListItemCss,
} from 'components/navigation/SideNavigation/styles';
import React from 'react';
import styled from 'styled-components';
import LocationIndicator from './LocationIndicator';

export const StyledPrimaryListItem = styled((props: any) => (
  <LocationIndicator isTopLevelItem {...props} />
))`
  ${styledPrimaryListItemCss}
`;

export const StyledSecondaryListItem = styled(LocationIndicator)`
  ${styledSecondaryListItemCss}
`;

export const Badge = styled.div`
  width: 21px;
  height: 21px;
  background: #fc5d3a;
  font-size: 13px;
  color: #d2d2d2;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-left: 8px;
`;
