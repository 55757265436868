import { PresetReportDto, ReportType } from 'api/admin/api';
import { TFunction } from 'i18next';

export interface PresetReportWithTypeDto extends PresetReportDto {
  reportType: ReportType;
}

export enum UnitTypeEnum {
  WaterColumnMillimeters = 'WaterColumnMillimeters',
  WaterColumnCentimeters = 'WaterColumnCentimeters',
  WaterColumnMeters = 'WaterColumnMeters',
  HydrogenMillimeters = 'HydrogenMillimeters',
  HydrogenCentimeters = 'HydrogenCentimeters',
  HydrogenMeters = 'HydrogenMeters',
  WaterColumnInches = 'WaterColumnInches',
  HydrogenInches = 'HydrogenInches',
  Millimeters = 'Millimeters',
  Centimeters = 'Centimeters',
  Meters = 'Meters',
  Inches = 'Inches',
  Feet = 'Feet',
  Yards = 'Yards',
  Liters = 'Liters',
  KiloLiters = 'KiloLiters',
  CubicMeters = 'CubicMeters',
  Default = 'Default',
  USGallons = 'USGallons',
  UKGallons = 'UKGallons',
  CubicInches = 'CubicInches',
  CubicFeet = 'CubicFeet',
  CubicYards = 'CubicYards',
  Kilograms = 'Kilograms',
  MetricTonne = 'MetricTonne',
  Pounds = 'Pounds',
  TonUS = 'TonUS',
  StandardCubicMeter = 'StandardCubicMeter',
  StandardCubicFoot = 'StandardCubicFoot',
  PercentFull = 'PercentFull',
}

export const unitOptions = (t: TFunction): Record<UnitTypeEnum, string> => {
  return {
    [UnitTypeEnum.WaterColumnMillimeters]: t('enum.unittype.mmwc', 'mm WC'),
    [UnitTypeEnum.WaterColumnCentimeters]: t('enum.unittype.cmwc', 'cm WC'),
    [UnitTypeEnum.WaterColumnMeters]: t('enum.unittype.mwc', 'm WC'),
    [UnitTypeEnum.HydrogenMillimeters]: t('enum.unittype.mh2', 'mm H2'),
    [UnitTypeEnum.HydrogenCentimeters]: t('enum.unittype.cmh2', 'cm H2'),
    [UnitTypeEnum.HydrogenMeters]: t('enum.unittype.mmh2', 'm H2'),
    [UnitTypeEnum.WaterColumnInches]: t('enum.unittype.inswc', 'Ins WC'),
    [UnitTypeEnum.HydrogenInches]: t('enum.unittype.insh2', 'Ins H2'),
    [UnitTypeEnum.Millimeters]: t('enum.unittype.mm', 'mm'),
    [UnitTypeEnum.Centimeters]: t('enum.unittype.cm', 'cm'),
    [UnitTypeEnum.Meters]: t('enum.unittype.m', 'm'),
    [UnitTypeEnum.Inches]: t('enum.unittype.in', 'in'),
    [UnitTypeEnum.Feet]: t('enum.unittype.ft', 'ft'),
    [UnitTypeEnum.Yards]: t('enum.unittype.yd', 'yd'),
    [UnitTypeEnum.Liters]: t('enum.unittype.l', 'l'),
    [UnitTypeEnum.KiloLiters]: t('enum.unittype.kl', 'kl'),
    [UnitTypeEnum.CubicMeters]: t('enum.unittype.m3', 'm3'),
    [UnitTypeEnum.Default]: t('enum.unittype.default', 'default'),
    [UnitTypeEnum.USGallons]: t('enum.unittype.galus', 'gal US'),
    [UnitTypeEnum.UKGallons]: t('enum.unittype.galimp', 'gal Imp'),
    [UnitTypeEnum.CubicInches]: t('enum.unittype.in3', 'in3'),
    [UnitTypeEnum.CubicFeet]: t('enum.unittype.ft3', 'ft3'),
    [UnitTypeEnum.CubicYards]: t('enum.unittype.yd3', 'yd3'),
    [UnitTypeEnum.Kilograms]: t('enum.unittype.kg', 'kg'),
    [UnitTypeEnum.MetricTonne]: t('enum.unittype.t', 't'),
    [UnitTypeEnum.Pounds]: t('enum.unittype.lbs', 'lbs'),
    [UnitTypeEnum.TonUS]: t('enum.unittype.ton', 'tons'),
    [UnitTypeEnum.StandardCubicMeter]: t('enum.unittype.scm', 'SCM'),
    [UnitTypeEnum.StandardCubicFoot]: t('enum.unittype.scf', 'SCF'),
    [UnitTypeEnum.PercentFull]: t('enum.unittype.percentfull', '% Full'),
  };
};

export enum FilterOptions {
  AllFields = 'AllFields',
  AssetLocation = 'AssetLocation',
  FTPID = 'FTPId',
  AssetTitle = 'Asset',
  Carrier = 'Carrier',
  CustomerName = 'CustomerName',
  Description = 'Description',
  Domain = 'Domain',
  RTU = 'RTU',
  RTUphone = 'RTUphone',
  Product = 'Product',
  Type = 'Type',
  Site = 'Site',
  PublishedComments = 'PublishedComments',
  State = 'State',
  City = 'City',
  Country = 'Country',
  ParentDomainName = 'ParentDomain',
  DomainName = 'DomainName',
  UserName = 'UserName',
  Manufacturer = 'Manufacturer',
  Company = 'Company',

  FirstName = 'FirstName',
  LastName = 'LastName',
  Role = 'Role',
  ShipTo = 'ShipTo',
}

export enum GroupByOptions {
  None = 'None',
  Asset = 'Asset',
  AssetLocation = 'Asset Location',
  Location = 'Location',
  CustomerName = 'CustomerName',
  Domain = 'Domain',
  RTU = 'RTU',
  State = 'State',
  Country = 'Country',
  Type = 'Type',
  Carrier = 'Carrier',
}

export enum ReportTemplateType {
  None = 0,
  AssetSummaryList = 101,
  DataChannelsWithMissingReadings = 102,
  MissingData = 103,
  NewAssetList = 104,
  LowBatteryVoltage = 105,
  DataChannelReading = 106,
  DataChannelForecast = 107,
  AssetTubeTrailerFillList = 108,
  AssetList = 201,
  RTUList = 202,
  DataChannelList = 203,
  SiteList = 204,
  TankDimensionList = 205,
  ProductList = 206,
  AssetGroupList = 207,
  DomainList = 208,
  UserList = 209,
  UserRoleList = 210,
  EventRuleGroupList = 211,
  DataChannelTemplateList = 212,
  CustomCSVReport = 301,
  HornerDOEReport = 401,
  HornerDetailReport = 402,
  StoredProcedureReport01 = 1001,
  StoredProcedureReport02 = 1002,
  StoredProcedureReport03 = 1003,
  StoredProcedureReport04 = 1004,
  StoredProcedureReport05 = 1005,
  StoredProcedureReport06 = 1006,
  StoredProcedureReport07 = 1007,
  StoredProcedureReport08 = 1008,
  StoredProcedureReport09 = 1009,
  StoredProcedureReport10 = 1010,
  StoredProcedureReport11 = 1011,
  StoredProcedureReport12 = 1012,
  StoredProcedureReport13 = 1013,
  StoredProcedureReport14 = 1014,
  StoredProcedureReport15 = 1015,
  StoredProcedureReport16 = 1016,
  StoredProcedureReport17 = 1017,
  StoredProcedureReport18 = 1018,
  StoredProcedureReport19 = 1019,
  StoredProcedureReport20 = 1020,
  StoredProcedureReport21 = 1021,
  StoredProcedureReport22 = 1022,
  StoredProcedureReport23 = 1023,
  StoredProcedureReport24 = 1024,
  StoredProcedureReport25 = 1025,
  StoredProcedureReport26 = 1026,
  StoredProcedureReport27 = 1027,
  StoredProcedureReport28 = 1028,
  StoredProcedureReport29 = 1029,
  StoredProcedureReport30 = 1030,
  StoredProcedureReport31 = 1031,
  StoredProcedureReport32 = 1032,
  StoredProcedureReport33 = 1033,
  StoredProcedureReport34 = 1034,
  StoredProcedureReport35 = 1035,
  StoredProcedureReport36 = 1036,
  StoredProcedureReport37 = 1037,
  StoredProcedureReport38 = 1038,
  StoredProcedureReport39 = 1039,
  StoredProcedureReport40 = 1040,
  StoredProcedureReport41 = 1041,
  StoredProcedureReport42 = 1042,
  StoredProcedureReport43 = 1043,
  StoredProcedureReport44 = 1044,
  StoredProcedureReport45 = 1045,
  StoredProcedureReport46 = 1046,
  StoredProcedureReport47 = 1047,
  StoredProcedureReport48 = 1048,
  StoredProcedureReport49 = 1049,
  StoredProcedureReport50 = 1050,
}
